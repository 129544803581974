import React from 'react';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode.js';
import {
  ButtonSolid,
  Icon,
  IconButton,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const TreenitScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  // filter
  const myFilter = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    console.log(list, textInputValue);
    const newList = list.filter(item =>
      item.person.toLowerCase().includes(textInputValue)
    );
    return newList;
  };

  const { theme } = props;
  const { navigation } = props;

  const xanoViikkoSuoritetuksiPATCH = XanoApi.useViikkoSuoritetuksiPATCH();

  const [Suoritetut_treenit, setSuoritetut_treenit] = React.useState([]);
  const [sliderValue, setSliderValue] = React.useState(0);

  return (
    <ScreenContainer
      style={styles.screen}
      scrollable={true}
      hasSafeArea={false}
      hasTopSafeArea={true}
    >
      <ImageBackground
        style={styles.ImageBackgrounda98db7de}
        resizeMode={'cover'}
        source={Images._249}
      >
        <View style={styles.Viewdebd3207}>
          <IconButton
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            style={styles.IconButtonf2dad91d}
            size={32}
            icon={'AntDesign/back'}
          />
          <Text style={[styles.Text55ce5766, { color: theme.colors.primary }]}>
            {'Treeniohjelma'}
          </Text>
        </View>

        <View style={styles.View02f8ec45}>
          <XanoApi.FetchTreenitGET
            email={Constants['USER_EMAIL']}
            viikko={props.route?.params?.Viikko ?? ''}
          >
            {({ loading, error, data, refetchTreenit }) => {
              const fetchData = data;
              if (!fetchData || loading) {
                return <ActivityIndicator />;
              }

              if (error) {
                return (
                  <Text style={{ textAlign: 'center' }}>
                    There was a problem fetching this data
                  </Text>
                );
              }

              return (
                <FlatList
                  data={fetchData}
                  listKey={'i9slIDms'}
                  keyExtractor={item => item?.id || item?.uuid || item}
                  renderItem={({ item }) => {
                    const listData = item;
                    return (
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('LiikkeetScreen', {
                              Treenin_kuvaus: listData?.Kuvaus,
                              treenin_nimi: listData?.Name,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={{ borderRadius: 5 }}
                      >
                        <Surface
                          style={[
                            styles.Surface75f9c3e1,
                            {
                              borderRadius: 5,
                              backgroundColor:
                                theme.colors.custom_rgb255_255_255,
                            },
                          ]}
                          elevation={3}
                        >
                          <View
                            style={[styles.View6b3c7e57, { borderRadius: 5 }]}
                          >
                            <View style={styles.View3be0cb3d}>
                              <Text
                                style={[
                                  styles.Text6f3f27fb,
                                  { color: theme.colors.strong },
                                ]}
                              >
                                {listData?.Name}
                              </Text>

                              <Text
                                style={[
                                  styles.Text6ceb0d4f,
                                  { color: theme.colors.strong },
                                ]}
                              >
                                {null}
                              </Text>
                              <Icon
                                size={24}
                                name={'Ionicons/podium-outline'}
                              />
                            </View>

                            <Surface
                              style={[
                                styles.Surface7b0e901a,
                                { borderRadius: 6 },
                              ]}
                            >
                              <Image
                                style={styles.Imagea98db7de}
                                resizeMode={'cover'}
                                source={{ uri: `${listData?.kuv_url}` }}
                              />
                            </Surface>
                          </View>
                        </Surface>
                      </Touchable>
                    );
                  }}
                  contentContainerStyle={styles.FlatList2a81e999Content}
                  numColumns={1}
                />
              );
            }}
          </XanoApi.FetchTreenitGET>
          <ButtonSolid
            onPress={() => {
              const handler = async () => {
                try {
                  await xanoViikkoSuoritetuksiPATCH.mutateAsync({
                    Viikko: props.route?.params?.Viikko ?? '',
                    email: Constants['USER_EMAIL'],
                  });
                  navigation.navigate('Otsikko', {
                    screen: 'ViikotScreen',
                    params: { viikonnimi: props.route?.params?.Viikko ?? '' },
                  });
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={[
              styles.ButtonSolid3ad332b8,
              { backgroundColor: theme.colors.primary },
            ]}
            title={'Merkitse viikko valmiiksi'}
          />
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  ButtonSolid3ad332b8: {
    borderRadius: 8,
    fontFamily: 'System',
    fontWeight: '700',
    fontSize: 18,
    marginBottom: 20,
    marginLeft: 19,
    marginRight: 19,
    textAlign: 'center',
  },
  Fetch431eb058: {
    minHeight: 40,
  },
  FlatList2a81e999Content: {
    flex: 1,
    marginBottom: 50,
  },
  IconButtonf2dad91d: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  ImageBackgrounda98db7de: {
    height: '100%',
    width: '100%',
  },
  Imagea98db7de: {
    height: '100%',
    width: '100%',
  },
  Surface75f9c3e1: {
    alignSelf: 'center',
    height: 100,
    marginBottom: 10,
    marginLeft: 15,
    marginRight: 15,
    minHeight: 40,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    width: 300,
  },
  Surface7b0e901a: {
    justifyContent: 'center',
    marginRight: 10,
    minHeight: 45,
    overflow: 'hidden',
    width: 130,
  },
  Text55ce5766: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 32,
    marginBottom: 10,
    marginLeft: 10,
    marginTop: 10,
  },
  Text6ceb0d4f: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 15,
  },
  Text6f3f27fb: {
    fontFamily: 'Nunito_500Medium',
    fontSize: 18,
  },
  View02f8ec45: {
    marginTop: 20,
  },
  View3be0cb3d: {
    height: '100%',
    marginLeft: 10,
    width: '50%',
  },
  View6b3c7e57: {
    alignSelf: 'center',
    flexDirection: 'row',
    height: 70,
    justifyContent: 'space-between',
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 7,
    width: 300,
  },
  Viewdebd3207: {
    flexDirection: 'row',
  },
  screen: {
    alignContent: 'center',
    alignSelf: 'stretch',
  },
});

export default withTheme(TreenitScreen);
