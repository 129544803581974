import React from 'react';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode.js';
import {
  Checkbox,
  Circle,
  CircleImage,
  Icon,
  IconButton,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const EtusivuScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  // Type the code for the body of your function or hook here.
  // Functions can be triggered via Button/Touchable actions.
  // Hooks are run per ReactJS rules.

  /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
  const { theme } = props;
  const { navigation } = props;

  const xanoAktivointiPOST = XanoApi.useAktivointiPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const aktivointi = await xanoAktivointiPOST.mutateAsync({
          email: Constants['USER_EMAIL'],
        });
        if (Constants['AUTHORIZATION_HEADER']) {
          return;
        }
        navigation.navigate('LoginScreen');
        setShowNav(false);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [Tervehdys, setTervehdys] = React.useState('');
  const [showNav, setShowNav] = React.useState(false);

  return (
    <ScreenContainer
      style={styles.screen}
      scrollable={false}
      hasSafeArea={false}
      hasTopSafeArea={true}
      hasBottomSafeArea={true}
    >
      <ImageBackground
        style={styles.ImageBackground766e4375}
        resizeMode={'cover'}
        source={Images._249}
      >
        <View
          style={[
            styles.View92afd6e4,
            { backgroundColor: theme.colors['lapinakyva'] },
          ]}
        >
          <IconButton
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'AUTHORIZATION_HEADER',
                  value: '',
                });
                navigation.navigate('LoginScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            size={32}
            icon={'MaterialIcons/exit-to-app'}
          />
          <Image
            style={styles.Imaged06b86ef}
            resizeMode={'cover'}
            source={Images.Ljk}
          />
          {/* openclose */}
          <Checkbox
            onPress={newOpencloseValue => {
              try {
                setShowNav(newOpencloseValue);
              } catch (err) {
                console.error(err);
              }
            }}
            checkedIcon={'Feather/x'}
            uncheckedIcon={'Feather/menu'}
            size={32}
          />
        </View>

        <XanoApi.FetchGetUserGET
          refetchInterval={5000}
          onData={fetchData => {
            const handler = async () => {
              try {
                setGlobalVariableValue({
                  key: 'USER_EMAIL',
                  value: fetchData?.email,
                });
                setGlobalVariableValue({
                  key: 'aktiivinen',
                  value: fetchData?.tilaus_aktiivinen,
                });
                setGlobalVariableValue({
                  key: 'name',
                  value: fetchData?.name,
                });
                setGlobalVariableValue({
                  key: 'kuntotesti_suoritettu',
                  value: fetchData?.kuntotesti_suoritetty,
                });
                setGlobalVariableValue({
                  key: 'tilaus',
                  value: fetchData?.tilaukset_kesto_kk,
                });
                await xanoAktivointiPOST.mutateAsync({
                  email: Constants['USER_EMAIL'],
                });
                if (fetchData?.tilaus_aktiivinen === true) {
                  return;
                }
                navigation.navigate('MaksumuuriScreen');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
        >
          {({ loading, error, data, refetchGetUser }) => {
            const fetchData = data;
            if (!fetchData || loading) {
              return <ActivityIndicator />;
            }

            if (error) {
              return (
                <Text style={{ textAlign: 'center' }}>
                  There was a problem fetching this data
                </Text>
              );
            }

            return (
              <>
                {/* Drawer */}
                <>
                  {!showNav ? null : (
                    <Surface
                      style={[
                        styles.Surfaceae9b9586,
                        { backgroundColor: theme.colors.surface },
                      ]}
                    >
                      <View
                        style={[
                          styles.View80a1b6f5,
                          { backgroundColor: theme.colors.strong },
                        ]}
                      >
                        <View style={styles.View7d6a39b7}>
                          <Circle size={64} bgColor={theme.colors.surface}>
                            <CircleImage
                              source={{
                                uri: 'https://global-uploads.webflow.com/5e740d74e6787687577e9b38/61eb13cf6bbef833d45c5560_avatar%20(5).png',
                              }}
                              size={60}
                            />
                          </Circle>

                          <View style={styles.Viewadfe599e}>
                            <Text
                              style={[
                                styles.Text21c1f2e9,
                                { color: theme.colors.surface },
                              ]}
                            >
                              {fetchData?.name?.toUpperCase()}
                            </Text>
                            <>
                              {!(
                                fetchData?.tilaus_aktiivinen === true
                              ) ? null : (
                                <View style={styles.View76d90f06}>
                                  <Circle
                                    size={8}
                                    bgColor={theme.colors['Studily_Mint_Green']}
                                  />
                                  <Text
                                    style={[
                                      styles.Texta42db935,
                                      {
                                        color:
                                          theme.colors['Studily_Mint_Green'],
                                      },
                                    ]}
                                  >
                                    {'Tilaus aktiivinen'}
                                  </Text>
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                      </View>

                      <View style={styles.Viewd35586ee}>
                        {/* Etusivu */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('Otsikko', {
                                screen: 'EtusivuScreen',
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles.Viewbd6f8013}>
                            <Icon
                              size={24}
                              name={'Ionicons/ios-home-outline'}
                            />
                            <Text
                              style={[
                                styles.Text5259fa1a,
                                { color: theme.colors.strong },
                              ]}
                            >
                              {'Etusivu'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Treeniohjelma */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('Otsikko', {
                                screen: 'ViikotScreen',
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles.Viewbd6f8013}>
                            <Icon
                              size={24}
                              name={'MaterialCommunityIcons/run'}
                            />
                            <Text
                              style={[
                                styles.Text5259fa1a,
                                { color: theme.colors.strong },
                              ]}
                            >
                              {'Treeniohjelma'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Messages */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('ChatScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles.Viewbd6f8013}>
                            <Icon name={'Feather/message-square'} size={24} />
                            <Text
                              style={[
                                styles.Text5259fa1a,
                                { color: theme.colors.strong },
                              ]}
                            >
                              {'Chat'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* TIlastot */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('SuoritetuttreenitScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles.Viewbd6f8013}>
                            <Icon
                              size={24}
                              name={'Ionicons/ios-stats-chart-outline'}
                            />
                            <Text style={styles.Textc3855374}>
                              {'Suoritetut Treenit'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Logout */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('LoginScreen');
                              setGlobalVariableValue({
                                key: 'AUTHORIZATION_HEADER',
                                value: '',
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles.View4ac3feef}>
                            <Icon name={'Feather/log-out'} size={24} />
                            <Text
                              style={[
                                styles.Textd06b3023,
                                { color: theme.colors.strong },
                              ]}
                            >
                              {'Kirjaudu ulos'}
                            </Text>
                          </View>
                        </Touchable>
                      </View>
                    </Surface>
                  )}
                </>
                <View style={styles.View6a8ef289}>
                  <Text
                    style={[
                      styles.Text8e0927f9,
                      { color: theme.colors['Internal_Primary_Bold'] },
                    ]}
                  >
                    {'Tervetuloa'}
                  </Text>

                  <Text
                    style={[
                      styles.Textcb884022,
                      { color: theme.colors['Primary'] },
                    ]}
                  >
                    {fetchData?.name}
                  </Text>
                </View>
              </>
            );
          }}
        </XanoApi.FetchGetUserGET>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  Fetch431eb058: {
    minHeight: 40,
  },
  ImageBackground766e4375: {
    height: '100%',
    opacity: 0.91,
    width: '100%',
  },
  Imaged06b86ef: {
    height: 100,
    width: 100,
  },
  Surfaceae9b9586: {
    flex: 2,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '80%',
    zIndex: 5,
  },
  Text21c1f2e9: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 18,
  },
  Text5259fa1a: {
    fontFamily: 'System',
    fontWeight: '400',
    fontSize: 18,
    marginLeft: 8,
  },
  Text8e0927f9: {
    alignSelf: 'flex-start',
    fontFamily: 'Nunito_500Medium',
    fontSize: 32,
    letterSpacing: 0.6,
    marginBottom: 5,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
  },
  Texta42db935: {
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 4,
  },
  Textc3855374: {
    fontFamily: 'System',
    fontWeight: '400',
    fontSize: 18,
    marginLeft: 8,
  },
  Textcb884022: {
    alignSelf: 'flex-end',
    fontFamily: 'Nunito_500Medium',
    fontSize: 32,
    letterSpacing: 0.6,
    marginBottom: 5,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    textTransform: 'uppercase',
  },
  Textd06b3023: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 18,
    marginLeft: 8,
  },
  View4ac3feef: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 12,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
  },
  View6a8ef289: {
    alignSelf: 'flex-start',
    height: 90,
    marginLeft: 30,
    marginRight: 30,
    marginTop: 70,
    width: 175,
  },
  View76d90f06: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 4,
  },
  View7d6a39b7: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  View80a1b6f5: {
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 72,
  },
  View92afd6e4: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  Viewadfe599e: {
    flex: 1,
    marginLeft: 12,
  },
  Viewbd6f8013: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 12,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
  },
  Viewd35586ee: {
    flex: 1,
    paddingBottom: 16,
    paddingTop: 16,
  },
  screen: {
    opacity: 1,
  },
});

export default withTheme(EtusivuScreen);
