import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import { IconButton, ScreenContainer, WebView } from '@draftbit/ui';
import { ImageBackground, StyleSheet } from 'react-native';

const ChatScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const { navigation } = props;

  const [Leuanveto, setLeuanveto] = React.useState(0);
  const [cooper, setCooper] = React.useState(0);
  const [hiihto, setHiihto] = React.useState(parseInt(1, 10));
  const [koordinaatio, setKoordinaatio] = React.useState(parseFloat(1.35, 10));
  const [leuanveto1, setLeuanveto1] = React.useState(0);
  const [punnerrus, setPunnerrus] = React.useState(0);
  const [selkalihakset, setSelkalihakset] = React.useState(0);
  const [uinti, setUinti] = React.useState(0);
  const [vatsalihakset, setVatsalihakset] = React.useState(0);
  const [vauhditonpituus, setVauhditonpituus] = React.useState(0);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      hasTopSafeArea={true}
    >
      <ImageBackground
        style={styles.ImageBackgroundaabce009}
        resizeMode={'cover'}
        source={Images._249}
      >
        <IconButton
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={styles.IconButtonbaacc924}
          size={32}
          icon={'AntDesign/back'}
        />
        <WebView
          style={styles.WebViewc992f941}
          javaScriptEnabled={true}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          cacheEnabled={true}
          source={{
            uri: `https://go.crisp.chat/chat/embed/?website_id=1e24d6a6-1f94-4240-a72b-b9c85e66dc0e&user_email=${Constants['USER_EMAIL']}`,
          }}
        />
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  IconButtonbaacc924: {
    marginLeft: 10,
    marginTop: 10,
  },
  ImageBackgroundaabce009: {
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  WebViewc992f941: {
    flex: 1,
  },
});

export default ChatScreen;
