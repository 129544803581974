import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const aktivointiPOST = (Constants, { email }) =>
  fetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/Aktivointi`, {
    body: JSON.stringify({ email: email }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useAktivointiPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => aktivointiPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('aktivaatio', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('aktivaatio');
        queryClient.invalidateQueries('aktivaatios');
      },
    }
  );
};

export const FetchAktivointiPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useAktivointiPOST({ email }, { refetchInterval });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchAktivointi: refetch });
};

export const getUserGET = Constants =>
  fetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/auth/me`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useGetUserGET = () => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/auth/me`, {
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
  });
};

export const FetchGetUserGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/auth/me`, {
    depends: [isFocused],
    headers: {
      Accept: 'application/json',
      Authorization: Constants['AUTHORIZATION_HEADER'],
      'Content-Type': 'application/json',
    },
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchGetUser: refetch });
};

export const liikkeetGET = (Constants, { email, treeni }) =>
  fetch(
    `https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/liikkeet?email=${
      email ?? ''
    }&treeni=${treeni ?? ''}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useLiikkeetGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['liikkeets', args], () => liikkeetGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchLiikkeetGET = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
  treeni,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useLiikkeetGET(
    { email, treeni },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchLiikkeet: refetch });
};

export const loginPOST = (Constants, { loginEmail, loginPassword }) =>
  fetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/auth/login`, {
    body: JSON.stringify({ email: loginEmail, password: loginPassword }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useLoginPOST = ({ loginEmail, loginPassword }) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();

  return useFetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/auth/login`, {
    body: JSON.stringify({ email: loginEmail, password: loginPassword }),
    depends: [isFocused],
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  });
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  loginEmail,
  loginPassword,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const refetch = () => {};
  const {
    isLoading: loading,
    data,
    error,
  } = useFetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/auth/login`, {
    body: JSON.stringify({ email: loginEmail, password: loginPassword }),
    depends: [isFocused],
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchLogin: refetch });
};

export const loppuverkatGET = Constants =>
  fetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/loppuverryttely`, {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useLoppuverkatGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['loppuverkkas', args],
    () => loppuverkatGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchLoppuverkatGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useLoppuverkatGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchLoppuverkat: refetch });
};

export const signupPOST = (
  Constants,
  { signupEmail, signupName, signupPassword }
) =>
  fetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/auth/signup`, {
    body: JSON.stringify({
      name: signupName,
      email: signupEmail,
      password: signupPassword,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useSignupPOST = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => signupPOST(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const FetchSignupPOST = ({
  children,
  onData = () => {},
  refetchInterval,
  signupEmail,
  signupName,
  signupPassword,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    loading,
    data,
    error,
    mutate: refetch,
  } = useSignupPOST(
    { signupEmail, signupName, signupPassword },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchSignup: refetch });
};

export const treenitGET = (Constants, { email, viikko }) =>
  fetch(
    `https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/treenit?email=${
      email ?? ''
    }&viikko=${viikko ?? ''}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useTreenitGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['treenits', args], () => treenitGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchTreenitGET = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
  viikko,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useTreenitGET(
    { email, viikko },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchTreenit: refetch });
};

export const viikotGET = (Constants, { email }) =>
  fetch(
    `https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/viikot_suorittamatta?email=${
      email ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useViikotGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['viikots', args], () => viikotGET(Constants, args), {
    refetchInterval,
  });
};

export const FetchViikotGET = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useViikotGET(
    { email },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchViikot: refetch });
};

export const asetaTasotPATCH = (
  Constants,
  {
    cooper,
    email,
    hiihtotas_teksti,
    koordinaatio,
    leuanveto,
    punnerrukset,
    selkalihas,
    uinti_aika,
    vatsalihas,
    vauhditonpituus,
  }
) =>
  fetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/aseta_tasot`, {
    body: JSON.stringify({
      email: email,
      leuanveto_maara: leuanveto,
      koordinaatio_aika: koordinaatio,
      vauhditonpituus: vauhditonpituus,
      punnerrukset_maara: punnerrukset,
      selkalihas_maara: selkalihas,
      vatsalihakset_maara: vatsalihas,
      aerobinen_kestavyys_matka: cooper,
      uinti_aika: uinti_aika,
      hiihtotaso: hiihtotas_teksti,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'PATCH',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useAsetaTasotPATCH = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => asetaTasotPATCH(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('tasot', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('tasot');
        queryClient.invalidateQueries('tasots');
      },
    }
  );
};

export const lammittelyGET = Constants =>
  fetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/lammittely`, {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useLammittelyGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['lammittelies', args],
    () => lammittelyGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchLammittelyGET = ({
  children,
  onData = () => {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useLammittelyGET(
    {},
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchLammittely: refetch });
};

export const lammittelyLiikkeetGET = (Constants, { treeni }) =>
  fetch(
    `https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/lammittely_liikkeet?treeni=${
      treeni ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useLammittelyLiikkeetGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['lammittelyliikkeetts', args],
    () => lammittelyLiikkeetGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchLammittelyLiikkeetGET = ({
  children,
  onData = () => {},
  refetchInterval,
  treeni,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useLammittelyLiikkeetGET(
    { treeni },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchLammittelyLiikkeet: refetch });
};

export const suoritetuTreenitGET = (Constants, { email }) =>
  fetch(
    `https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/suoritetut_treenit?email=${
      email ?? ''
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useSuoritetuTreenitGET = (args, { refetchInterval } = {}) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['treenits', args],
    () => suoritetuTreenitGET(Constants, args),
    {
      refetchInterval,
    }
  );
};

export const FetchSuoritetuTreenitGET = ({
  children,
  onData = () => {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const { loading, data, error, refetch } = useSuoritetuTreenitGET(
    { email },
    { refetchInterval }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  React.useEffect(() => {
    if (data) {
      onData(data);
    }
  }, [data]);

  return children({ loading, data, error, refetchSuoritetuTreenit: refetch });
};

export const treeniSuoritetuksiPATCH = (Constants, { email, treeni }) =>
  fetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/treeni_suoritetuksi`, {
    body: JSON.stringify({ email: email, treeni: treeni }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'PATCH',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useTreeniSuoritetuksiPATCH = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => treeniSuoritetuksiPATCH(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('treenit', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('treenit');
        queryClient.invalidateQueries('treenits');
      },
    }
  );
};

export const viikkoSuoritetuksiPATCH = (Constants, { Viikko, email }) =>
  fetch(`https://x6r0-a8wx-aiv1.f2.xano.io/api:a6MwY-8C/viikko_suoritetuksi`, {
    body: JSON.stringify({ email: email, Viikko: Viikko }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'PATCH',
  })
    .then(res => {
      if (!res.ok) {
        console.error('Fetch error: ' + res.status + ' ' + res.statusText);
      }
      return res;
    })
    .then(res => res.json())
    .catch(() => {});

export const useViikkoSuoritetuksiPATCH = initialArgs => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();

  return useMutation(
    args => viikkoSuoritetuksiPATCH(Constants, { ...initialArgs, ...args }),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('viikot', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('viikot');
        queryClient.invalidateQueries('viikots');
      },
    }
  );
};
