import React from 'react';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import {
  ButtonSolid,
  NumberInput,
  RadioButtonGroup,
  RadioButtonRow,
  ScreenContainer,
  Swiper,
  SwiperItem,
  withTheme,
} from '@draftbit/ui';
import { Image, ImageBackground, StyleSheet, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const TasotestitScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const xanoAsetaTasotPATCH = XanoApi.useAsetaTasotPATCH();

  const [Leuanveto, setLeuanveto] = React.useState(0);
  const [cooper, setCooper] = React.useState(0);
  const [hiihto, setHiihto] = React.useState(parseInt(1, 10));
  const [koordinaatio, setKoordinaatio] = React.useState(parseFloat(1.35, 10));
  const [leuanveto1, setLeuanveto1] = React.useState(0);
  const [punnerrus, setPunnerrus] = React.useState(0);
  const [selkalihakset, setSelkalihakset] = React.useState(0);
  const [uinti, setUinti] = React.useState(0);
  const [vatsalihakset, setVatsalihakset] = React.useState(0);
  const [vauhditonpituus, setVauhditonpituus] = React.useState(0);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      hasTopSafeArea={true}
    >
      <KeyboardAwareScrollView
        contentContainerStyle={styles.KeyboardAwareScrollViewac426b39Content}
        showsVerticalScrollIndicator={true}
        keyboardShouldPersistTaps={'never'}
        enableAutomaticScroll={true}
      />
      <ImageBackground
        style={styles.ImageBackgroundaabce009}
        resizeMode={'cover'}
        source={Images._249}
      >
        <Swiper
          style={styles.Swiperc8525ce8}
          dotColor={theme.colors.light}
          dotActiveColor={theme.colors.primary}
          dotsTouchable={true}
          from={0}
          nextTitle={'->'}
        >
          <SwiperItem style={styles.SwiperItemb73ffc3e}>
            <View style={styles.Viewb1dbdfca}>
              <Text
                style={[styles.Text42728e52, { color: theme.colors.strong }]}
              >
                {'Omat kuntotasot'}
              </Text>

              <Text
                style={[styles.Text292c02a9, { color: theme.colors.strong }]}
              >
                {
                  'Aloita kuntotasojen tarkistus. \nTekemällä aloituskuntotestin, saadaan sinulle optimoitu treeniohjelma, joka kehittyy mukanasi aina kun päivität kuntotasojasi. \nTee kuntotesti uudestaan 3 kk välein.'
                }
              </Text>
            </View>
          </SwiperItem>

          <SwiperItem style={styles.SwiperItemb73ffc3e}>
            <View style={styles.Viewe47dfa91}>
              <Text
                style={[styles.Textb7ff96a5, { color: theme.colors.strong }]}
              >
                {'Leuanveto'}
              </Text>

              <Text
                style={[styles.Text114936f1, { color: theme.colors.strong }]}
              >
                {
                  'Riiputaan rekissä vastaotteella, jalat ja kädet suorana. \nKoukistetaan käsiä, kunnes leuka on rekin yläpuolella. \nLasketaan kädet suoriksi. \nLiike toistetaan yhtäjaksoisesti. '
                }
              </Text>

              <View style={styles.View63c1da9f}>
                <NumberInput
                  onChangeText={newNumberInputValue => {
                    try {
                      const valuel6AjshTx = newNumberInputValue;
                      setLeuanveto1(valuel6AjshTx);
                      const leuanveto = valuel6AjshTx;
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={[
                    styles.NumberInput743a08a0,
                    { backgroundColor: theme.colors['Overlay_Style_2'] },
                  ]}
                  value={parseInt(leuanveto1, 10)}
                  editable={true}
                  keyboardType={'number-pad'}
                  selectTextOnFocus={true}
                  placeholder={'Esim. 8'}
                />
                <Text
                  style={[styles.Textcc54e7b3, { color: theme.colors.strong }]}
                >
                  {'Toistoa'}
                </Text>
              </View>
            </View>
          </SwiperItem>

          <SwiperItem style={styles.SwiperItem40cfaecb}>
            <View style={styles.Viewe47dfa91}>
              <Text
                style={[styles.Text1b761c90, { color: theme.colors.strong }]}
              >
                {'Koordinaationopeus'}
              </Text>

              <Text
                style={[styles.Texte842d9da, { color: theme.colors.strong }]}
              >
                {
                  'Viivajuoksu 10 suoritusta + vartalonhallinta 10 suoritusta\n\nViivajuoksun lähtöasennossa seisotaan sivuttain, käsi toisella viivalla\n- Käydään koskettamassa kädellä 10 kertaa toista viivaa, liikkuminen sivuttain\n- Viivojen väli neljä metriä\n- Heti viivajuoksun perään tehdään 10 kertaa vartalonhallintaliikettä\n- Lähtöasennossa seisotaan kädet ylhäällä, vartalo suorana\n- Suoritus (yksi liike): laskeudutaan päinmakuulle, kädet suoraksi eteen à noustaan ylös, kädet\nsuoraksi ylös à laskeudutaan selinmakuulle, kädet suoraksi taakse à noustaan ylös, kädet\nsuoraksi ylös.'
                }
              </Text>

              <View style={styles.Viewbbc4a35f}>
                <NumberInput
                  onChangeText={newNumberInputValue => {
                    try {
                      const valueTp0zwfme = parseFloat(newNumberInputValue, 10);
                      setKoordinaatio(valueTp0zwfme);
                      const koordinaatiotaso = valueTp0zwfme;
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={[
                    styles.NumberInput743a08a0,
                    { backgroundColor: theme.colors['Overlay_Style_2'] },
                  ]}
                  value={parseFloat(koordinaatio, 10)}
                  editable={true}
                  selectTextOnFocus={true}
                  keyboardType={'numbers-and-punctuation'}
                  placeholder={'esim. 1.30'}
                />
                <Text
                  style={[styles.Text366cc2a8, { color: theme.colors.strong }]}
                >
                  {'min'}
                </Text>
              </View>
            </View>
          </SwiperItem>

          <SwiperItem style={styles.SwiperItemb73ffc3e}>
            <View style={styles.Viewe47dfa91}>
              <Text
                style={[styles.Text1b761c90, { color: theme.colors.strong }]}
              >
                {'Punnerrus'}
              </Text>

              <Text
                style={[styles.Text114936f1, { color: theme.colors.strong }]}
              >
                {
                  '- Suoritusaika 60s\n- Päinmakuu, kädet hartioiden leveydellä, sormet eteenpäin\n- Jalat hartioiden leveydellä\n- Yläasennossa kädet ja vartalo suorana\n- Ala-asennossa kädet ovat 90 asteen kulmassa ja vartalo suorana.\nVirheet: vartalo taipuu selästä tai lantiosta, polvet koukistuvat, kädet eivät ojennu suoriksi.'
                }
              </Text>

              <View style={styles.View8caf2f57}>
                <NumberInput
                  onChangeText={newNumberInputValue => {
                    try {
                      const valueDqKwKi3Z = newNumberInputValue;
                      setPunnerrus(valueDqKwKi3Z);
                      const punnerruksia = valueDqKwKi3Z;
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={[
                    styles.NumberInputab04c859,
                    { backgroundColor: theme.colors['Overlay_Style_2'] },
                  ]}
                  value={punnerrus}
                  editable={true}
                  selectTextOnFocus={true}
                  keyboardType={'numbers-and-punctuation'}
                  placeholder={'esim. 1.30'}
                />
                <Text
                  style={[styles.Textcc54e7b3, { color: theme.colors.strong }]}
                >
                  {'toistoa'}
                </Text>
              </View>
            </View>
          </SwiperItem>

          <SwiperItem style={styles.SwiperItemb73ffc3e}>
            <View style={styles.Viewe47dfa91}>
              <Text
                style={[styles.Text1b761c90, { color: theme.colors.strong }]}
              >
                {'Vauhditonpituus'}
              </Text>

              <Text
                style={[styles.Text114936f1, { color: theme.colors.strong }]}
              >
                {
                  '- Tasaponnistus jalat rinnakkain ja alastulo\n- Tulos mitataan takimmaisen jalan kantapään kohdalta\n- Kaksi suoritusta, parempi tulos jää voimaan.'
                }
              </Text>

              <View style={styles.View8caf2f57}>
                <NumberInput
                  onChangeText={newNumberInputValue => {
                    try {
                      const valueIwgX4uWV = newNumberInputValue;
                      setVauhditonpituus(valueIwgX4uWV);
                      const punnerruksia = valueIwgX4uWV;
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={[
                    styles.NumberInput0da11696,
                    { backgroundColor: theme.colors['Overlay_Style_2'] },
                  ]}
                  value={vauhditonpituus}
                  editable={true}
                  selectTextOnFocus={true}
                  keyboardType={'numbers-and-punctuation'}
                  placeholder={'esim. 1.30'}
                />
                <Text
                  style={[styles.Textcc54e7b3, { color: theme.colors.strong }]}
                >
                  {'m    esim. 2.30'}
                </Text>
              </View>
            </View>
          </SwiperItem>

          <SwiperItem style={styles.SwiperItemb73ffc3e}>
            <View style={styles.Viewe47dfa91}>
              <Text
                style={[styles.Text1b761c90, { color: theme.colors.strong }]}
              >
                {'Cooperin testi'}
              </Text>

              <Text
                style={[styles.Textfda6acbb, { color: theme.colors.strong }]}
              >
                {
                  'Aerobisen kestävyyden kuntotestinä tehdään Cooperin testi eli juostaan 12 minuutin ajan ja katsotaan kuinka pitkälle päästään.'
                }
              </Text>

              <View style={styles.View8caf2f57}>
                <NumberInput
                  onChangeText={newNumberInputValue => {
                    try {
                      const valueoXBuK7ai = newNumberInputValue;
                      setCooper(valueoXBuK7ai);
                      const punnerruksia = valueoXBuK7ai;
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={[
                    styles.NumberInputab04c859,
                    { backgroundColor: theme.colors['Overlay_Style_2'] },
                  ]}
                  value={parseInt(cooper, 10)}
                  editable={true}
                  selectTextOnFocus={true}
                  keyboardType={'numbers-and-punctuation'}
                  placeholder={'esim. 1.30'}
                />
                <Text style={{ color: theme.colors.strong }}>
                  {'m   esim. 2300'}
                </Text>
              </View>
            </View>
          </SwiperItem>

          <SwiperItem style={styles.SwiperItemb73ffc3e}>
            <View style={styles.Viewe47dfa91}>
              <Text
                style={[styles.Text1b761c90, { color: theme.colors.strong }]}
              >
                {'Vatsalihakset'}
              </Text>

              <Text
                style={[styles.Textfda6acbb, { color: theme.colors.strong }]}
              >
                {
                  '- Suoritusaika 60s\n- Selinmakuu, kädet niskan takana, sormet ristissä, kyynärpäät eteenpäin\nJalat 90 asteen kulmassa, avustaja tukee nilkoista\n- Noustaan istumaan, kosketetaan kyynärpäillä polvia\n- Laskeudutaan alas siten, että hartiat koskettavat alustaa'
                }
              </Text>

              <View style={styles.View9041d01c}>
                <NumberInput
                  onChangeText={newNumberInputValue => {
                    try {
                      const valueJf1PFlkc = newNumberInputValue;
                      setVatsalihakset(valueJf1PFlkc);
                      const punnerruksia = valueJf1PFlkc;
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={[
                    styles.NumberInputab04c859,
                    { backgroundColor: theme.colors['Overlay_Style_2'] },
                  ]}
                  value={vatsalihakset}
                  editable={true}
                  selectTextOnFocus={true}
                  keyboardType={'numbers-and-punctuation'}
                  placeholder={'esim. 1.30'}
                />
              </View>
            </View>
          </SwiperItem>

          <SwiperItem style={styles.SwiperItemb73ffc3e}>
            <View style={styles.Viewe47dfa91}>
              <Text
                style={[styles.Text1b761c90, { color: theme.colors.strong }]}
              >
                {'Selkälihakset'}
              </Text>

              <Text
                style={[styles.Text114936f1, { color: theme.colors.strong }]}
              >
                {
                  '- Suoritusaika 60s\n- Päinmakuulla, kädet niskan takana sormet ristissä\n- Avustaja tukee nilkoista\n- Nostetaan ylävartaloa 30 cm\n- Laskeudutaan alas, siten että rinta koskettaa alustaa.'
                }
              </Text>

              <View style={styles.View9041d01c}>
                <NumberInput
                  onChangeText={newNumberInputValue => {
                    try {
                      const valueDWfvDP66 = newNumberInputValue;
                      setSelkalihakset(valueDWfvDP66);
                      const punnerruksia = valueDWfvDP66;
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={[
                    styles.NumberInputab04c859,
                    { backgroundColor: theme.colors['Overlay_Style_2'] },
                  ]}
                  value={selkalihakset}
                  editable={true}
                  selectTextOnFocus={true}
                  keyboardType={'numbers-and-punctuation'}
                  placeholder={'esim. 1.30'}
                />
              </View>
            </View>
          </SwiperItem>

          <SwiperItem style={styles.SwiperItemb73ffc3e}>
            <View style={styles.Viewe47dfa91}>
              <Text
                style={[styles.Textb7ff96a5, { color: theme.colors.strong }]}
              >
                {'Uinti'}
              </Text>

              <Text
                style={[styles.Text5a5b48d9, { color: theme.colors.strong }]}
              >
                {
                  'Lähtö altaan reunalta hyppäämällä\n- 200 metriä vapaalla tyylillä (selkäuinti kielletty)\n- Käytettävä uimahousuja (ei shortseja)\n- Uimalasien käyttö kielletty.'
                }
              </Text>

              <View style={styles.View8caf2f57}>
                <NumberInput
                  onChangeText={newNumberInputValue => {
                    try {
                      const valueaW1HHdcI = newNumberInputValue;
                      setUinti(valueaW1HHdcI);
                      const punnerruksia = valueaW1HHdcI;
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={[
                    styles.NumberInput0da11696,
                    { backgroundColor: theme.colors['Overlay_Style_2'] },
                  ]}
                  value={uinti}
                  editable={true}
                  selectTextOnFocus={true}
                  keyboardType={'numbers-and-punctuation'}
                  placeholder={'esim. 1.30'}
                />
                <Text
                  style={[styles.Textcc54e7b3, { color: theme.colors.strong }]}
                >
                  {'aika   esim. 2.40'}
                </Text>
              </View>
            </View>
          </SwiperItem>

          <SwiperItem style={styles.SwiperItemb73ffc3e}>
            <View style={styles.Viewe47dfa91}>
              <Text
                style={[styles.Text1b761c90, { color: theme.colors.strong }]}
              >
                {'Hiihto'}
              </Text>

              <Text
                style={[styles.Text114936f1, { color: theme.colors.strong }]}
              >
                {'Arvioi omaa hiihtotasoasi asteikolla Huono - Erinomainen'}
              </Text>
              <View style={styles.View9041d01c} />
              <RadioButtonGroup
                onValueChange={newRadioButtonGroupValue => {
                  try {
                    const hiihtonumero = setGlobalVariableValue({
                      key: 'hiihto',
                      value: parseInt(newRadioButtonGroupValue, 10),
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                direction={'vertical'}
                defaultValue={1}
              >
                <RadioButtonRow
                  style={styles.RadioButtonRowcbde0408}
                  label={'Huono'}
                  value={parseInt(1, 10)}
                  color={theme.colors.primary}
                  unselectedColor={theme.colors.primary}
                  direction={'row'}
                />
                <RadioButtonRow
                  style={styles.RadioButtonRowcbde0408}
                  label={'Kohtalainen'}
                  value={parseInt(2, 10)}
                  color={theme.colors.primary}
                  unselectedColor={theme.colors.primary}
                  direction={'row'}
                />
                <RadioButtonRow
                  style={styles.RadioButtonRowcbde0408}
                  label={'Hyvä'}
                  value={parseInt(3, 10)}
                  color={theme.colors.primary}
                  unselectedColor={theme.colors.primary}
                  direction={'row'}
                />
                <RadioButtonRow
                  style={styles.RadioButtonRowcbde0408}
                  label={'Erinomainen'}
                  value={parseInt(4, 10)}
                  color={theme.colors.primary}
                  unselectedColor={theme.colors.primary}
                  direction={'row'}
                />
              </RadioButtonGroup>
            </View>
          </SwiperItem>

          <SwiperItem style={styles.SwiperItemb73ffc3e}>
            <View style={styles.Viewe47dfa91}>
              <Text
                style={[styles.Textb7ff96a5, { color: theme.colors.strong }]}
              >
                {'Valmista tuli'}
              </Text>

              <Text
                style={[styles.Text114936f1, { color: theme.colors.strong }]}
              >
                {
                  'Pian pääset treenaamaan. Anna vielä algoritmin optimoida harjoitusohjelma ilmoittamiesi lukujen perusteella'
                }
              </Text>
              <ButtonSolid
                onPress={() => {
                  const handler = async () => {
                    try {
                      await xanoAsetaTasotPATCH.mutateAsync({
                        cooper: cooper,
                        email: Constants['USER_EMAIL'],
                        hiihtotas_teksti: parseInt(hiihto, 10),
                        koordinaatio: koordinaatio,
                        leuanveto: leuanveto1,
                        punnerrukset: punnerrus,
                        selkalihas: selkalihakset,
                        uinti_aika: uinti,
                        vatsalihas: vatsalihakset,
                        vauhditonpituus: vauhditonpituus,
                      });
                      setGlobalVariableValue({
                        key: 'kuntotesti_suoritettu',
                        value: true,
                      });
                      navigation.navigate('Otsikko', {
                        screen: 'EtusivuScreen',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={[
                  styles.ButtonSolide94087dc,
                  { backgroundColor: theme.colors.primary },
                ]}
                title={'Tallenna'}
              />
            </View>
          </SwiperItem>
        </Swiper>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  ButtonSolide94087dc: {
    borderRadius: 45,
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 18,
    marginBottom: 10,
    marginLeft: 18,
    marginRight: 18,
    marginTop: 50,
    textAlign: 'center',
  },
  Image0862e91c: {
    height: 120,
    marginTop: 10,
    width: 250,
  },
  ImageBackgroundaabce009: {
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  KeyboardAwareScrollViewac426b39Content: {
    opacity: 0,
  },
  NumberInput0da11696: {
    borderRadius: 8,
    fontFamily: 'OpenSans_400Regular',
    fontSize: 25,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: '1%',
    paddingTop: 8,
    textAlign: 'center',
    width: 175,
  },
  NumberInput743a08a0: {
    borderRadius: 8,
    fontFamily: 'OpenSans_400Regular',
    fontSize: 25,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    textAlign: 'center',
    width: 175,
  },
  NumberInputab04c859: {
    borderRadius: 8,
    fontFamily: 'Nunito_400Regular',
    fontSize: 25,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: '1%',
    paddingTop: 8,
    textAlign: 'center',
    width: 175,
  },
  RadioButtonRowcbde0408: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
  },
  SwiperItem40cfaecb: {
    alignContent: 'center',
    flex: 1,
  },
  SwiperItemb73ffc3e: {
    alignContent: 'center',
  },
  Swiperc8525ce8: {
    height: '80%',
    width: '100%',
  },
  Text114936f1: {
    alignSelf: 'stretch',
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
    textAlign: 'left',
  },
  Text1b761c90: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 32,
    marginBottom: 5,
  },
  Text292c02a9: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  Text366cc2a8: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 14,
  },
  Text42728e52: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 32,
    marginBottom: 50,
  },
  Text5a5b48d9: {
    alignSelf: 'stretch',
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
    textAlign: 'left',
  },
  Textb7ff96a5: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 32,
    marginBottom: 5,
  },
  Textcc54e7b3: {
    fontFamily: 'Nunito_400Regular',
  },
  Texte842d9da: {
    alignSelf: 'stretch',
    fontFamily: 'Nunito_400Regular',
    fontSize: 15,
    textAlign: 'left',
  },
  Textfda6acbb: {
    alignSelf: 'stretch',
    fontFamily: 'Nunito_300Light',
    fontSize: 18,
    textAlign: 'left',
  },
  View63c1da9f: {
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 60,
  },
  View8caf2f57: {
    alignContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 60,
  },
  View9041d01c: {
    alignContent: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 60,
  },
  View9d5131b0: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  Viewb1dbdfca: {
    alignContent: 'center',
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    left: 18,
    right: 18,
  },
  Viewbbc4a35f: {
    alignContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
  },
  Viewe47dfa91: {
    alignContent: 'center',
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    marginLeft: 18,
    marginRight: 18,
  },
});

export default withTheme(TasotestitScreen);
