import React from 'react';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import {
  ButtonSolid,
  Link,
  ScreenContainer,
  Spacer,
  withTheme,
} from '@draftbit/ui';
import {
  ImageBackground,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const LoginScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  return (
    <ScreenContainer>
      <ImageBackground
        style={styles.ImageBackgrounda98db7de}
        resizeMode={'cover'}
        source={Images._20091109MG11111}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={styles.KeyboardAwareScrollView2b66e99eContent}
        >
          {/* Header */}
          <View style={styles.View39912261}>
            {/* Title */}
            <Text
              style={[
                styles.Textebfd754b,
                { color: theme.colors['Background'] },
              ]}
            >
              {'Tervetuloa Takaisin'}
            </Text>
            {/* Subtitle */}
            <Text
              style={[
                styles.Text4ac40d33,
                { color: theme.colors['Background'] },
              ]}
            >
              {'Kirjaudu sisään jatkaaksesi harjoittelua'}
            </Text>
          </View>
          {/* Login Form */}
          <View style={styles.View1e98c651}>
            {/* Error Message */}
            <Text style={[styles.Text6789b8ec, { color: theme.colors.error }]}>
              {null}
            </Text>
            {/* Email Input */}
            <TextInput
              onChangeText={newEmailInputValue => {
                try {
                  setEmail(newEmailInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={[
                styles.TextInput1907a89b,
                {
                  borderColor: theme.colors.divider,
                  color: theme.colors['Background'],
                },
              ]}
              value={null}
              placeholder={'Email'}
              keyboardType={'email-address'}
              textContentType={'emailAddress'}
              autoCapitalize={'none'}
            />
            <Spacer top={12} right={8} bottom={12} left={8} />
            {/* Password Input */}
            <TextInput
              onChangeText={newPasswordInputValue => {
                try {
                  setPassword(newPasswordInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={[
                styles.TextInputf47d88b5,
                {
                  borderColor: theme.colors.divider,
                  color: theme.colors['Background'],
                },
              ]}
              value={null}
              placeholder={'Password'}
              secureTextEntry={true}
            />
            <Spacer top={24} right={8} bottom={24} left={8} />
            {/* Sign In Button */}
            <ButtonSolid
              onPress={() => {
                const handler = async () => {
                  try {
                    const loginResponseJson = await XanoApi.loginPOST(
                      Constants,
                      { loginEmail: email, loginPassword: password }
                    );
                    const authToken = loginResponseJson.authToken;
                    const message = loginResponseJson.message;
                    setGlobalVariableValue({
                      key: 'ERROR_MESSAGE',
                      value: message,
                    });
                    if (!authToken) {
                      return;
                    }
                    setGlobalVariableValue({
                      key: 'AUTHORIZATION_HEADER',
                      value: 'Bearer ' + authToken,
                    });
                    navigation.navigate('Otsikko', { screen: 'EtusivuScreen' });
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={[
                styles.ButtonSolidfe5f3af3,
                { backgroundColor: theme.colors.primary },
              ]}
              title={'Kirjaudu Sisään'}
            />
            <Spacer top={16} right={8} bottom={16} left={8} />
            <View style={styles.View8bb6a2bc}>
              <Text style={{ color: theme.colors['Background'] }}>
                {'Uusi Käyttäjä?'}
              </Text>
              <Spacer top={8} right={2} bottom={8} left={2} />
              {/* Sign Up Link */}
              <Link
                onPress={() => {
                  try {
                    navigation.navigate('RegisterScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={{ color: theme.colors['Background'] }}
                title={'Rekisteröidy'}
              />
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  ButtonSolidfe5f3af3: {
    borderRadius: 8,
    fontFamily: 'System',
    fontWeight: '700',
    paddingBottom: 16,
    paddingTop: 16,
    textAlign: 'center',
  },
  ImageBackgrounda98db7de: {
    height: '100%',
    width: '100%',
  },
  KeyboardAwareScrollView2b66e99eContent: {
    flex: 1,
    justifyContent: 'center',
  },
  Text4ac40d33: {
    fontFamily: 'System',
    fontWeight: '400',
    fontSize: 14,
    marginTop: 4,
  },
  Text6789b8ec: {
    fontSize: 12,
    marginBottom: 16,
    textAlign: 'center',
  },
  TextInput1907a89b: {
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRadius: 8,
    borderRightWidth: 1,
    borderTopWidth: 1,
    fontFamily: 'System',
    fontWeight: '400',
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
  },
  TextInputf47d88b5: {
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRadius: 8,
    borderRightWidth: 1,
    borderTopWidth: 1,
    fontFamily: 'System',
    fontWeight: '400',
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
  },
  Textebfd754b: {
    fontFamily: 'System',
    fontWeight: '600',
    fontSize: 36,
    textAlign: 'center',
  },
  View1e98c651: {
    marginTop: 24,
    paddingLeft: 36,
    paddingRight: 36,
  },
  View39912261: {
    alignItems: 'center',
  },
  View8bb6a2bc: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 12,
  },
});

export default withTheme(LoginScreen);
