import { systemWeights } from 'react-native-typography';
export default {
  disabledOpacity: 0.5,
  roundness: 6,
  colors: {
    Background: 'rgba(251, 252, 253, 1)',
    'Custom #0000ff': 'rgb(0, 0, 255)',
    'Custom #101010': 'rgb(16, 16, 16)',
    'Custom #222222': 'rgb(34, 34, 34)',
    'Custom #333333': 'rgb(51, 51, 51)',
    'Custom #6174f6': 'rgb(97, 116, 246)',
    'Custom #666666': 'rgb(102, 102, 102)',
    'Custom #999999': 'rgb(153, 153, 153)',
    'Custom #a0abf9': 'rgb(160, 171, 249)',
    'Custom #ccd1fa': 'rgb(204, 209, 250)',
    'Custom #cfcfcf': 'rgb(207, 207, 207)',
    'Custom #dadada': 'rgb(218, 218, 218)',
    'Custom #dddddd': 'rgb(221, 221, 221)',
    'Custom #dfdfdf': 'rgb(223, 223, 223)',
    'Custom #e9ecfc': 'rgb(233, 236, 252)',
    'Custom #efefef': 'rgb(239, 239, 239)',
    'Custom #f1f3f6': 'rgb(241, 243, 246)',
    'Custom #f5f7f8': 'rgb(245, 247, 248)',
    'Custom #fcfccf': 'rgb(252, 252, 207)',
    'Custom #fcfcfc': 'rgb(252, 252, 252)',
    'Custom #fdfdfd': 'rgb(253, 253, 253)',
    'Custom #ff0000': 'rgb(255, 0, 0)',
    'Custom #ff5f00': 'rgb(255, 95, 0)',
    'Custom #ffa500': 'rgb(255, 165, 0)',
    'Custom #ffffff': 'rgb(255, 255, 255)',
    'Custom Color': 'rgb(0, 128, 0)',
    'Custom Color_2': 'rgb(0, 0, 0)',
    Divider: 'rgba(234, 237, 242, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    Icon_Opacity: 'rgba(0, 0, 0, 0.25)',
    Internal_Background: 'rgb(160, 171, 249)',
    Internal_Barely_White: 'rgb(253, 253, 253)',
    Internal_Blonde_White: 'rgb(223, 223, 223)',
    Internal_Blue: 'rgb(0, 0, 255)',
    Internal_Border: 'rgb(218, 218, 218)',
    Internal_Bronzed_Gray: 'rgb(153, 153, 153)',
    Internal_Egg_White: 'rgb(252, 252, 252)',
    Internal_Ever_Blue_White: 'rgb(233, 236, 252)',
    Internal_Hot_Red: 'rgb(255, 0, 0)',
    Internal_Light_Background: 'rgb(241, 243, 246)',
    Internal_Light_Gray: 'rgb(207, 207, 207)',
    Internal_Light_Icon_BG: 'rgb(204, 209, 250)',
    Internal_Light_Stone: 'rgb(221, 221, 221)',
    Internal_Light_White: 'rgb(239, 239, 239)',
    Internal_Orange_Secondary_Alt: 'rgb(255, 95, 0)',
    Internal_Primary_Bold: 'rgb(16, 16, 16)',
    Internal_Primary_Color: 'rgb(97, 116, 246)',
    Internal_Primary_Dark: 'rgb(51, 51, 51)',
    Internal_Secondary_Dark: 'rgb(102, 102, 102)',
    Internal_Soft_Snow_White: 'rgb(245, 247, 248)',
    Internal_UI_Dark: 'rgb(34, 34, 34)',
    Internal_White: 'rgb(255, 255, 255)',
    Internal_Yellow: 'rgb(252, 252, 207)',
    Internal_Yellow_Secondary: 'rgb(255, 165, 0)',
    Light: 'rgba(165, 173, 183, 1)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    Light_Inverse: 'rgba(255, 255, 255, 0.68)',
    Medium: 'rgba(70, 78, 88, 1)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    Medium_Inverse: 'rgba(255, 255, 255, 0.87)',
    Opacity_Blue_for_Button: 'rgba(85, 108, 246, 0.05)',
    Option_Selected_Color: 'rgb(97, 214, 204)',
    Overlay: 'rgba(0, 0, 0, 0.39)',
    Overlay_Style_2: 'rgba(255, 255, 255, 0.49)',
    Primary: 'rgb(17, 17, 19)',
    Secondary: 'rgba(59, 201, 234, 1)',
    Settings_Icon_BG_Color: 'rgb(175, 185, 249)',
    Strong: 'rgba(18, 20, 44, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    Strong_Inverse: 'rgba(255, 255, 255, 1)',
    'Studily 25 Percent': 'rgba(0, 0, 0, 0.25)',
    'Studily Bright Mint': 'rgb(126, 209, 172)',
    'Studily Dark UI': 'rgb(17, 17, 17)',
    'Studily Emerald Mint': 'rgb(97, 214, 204)',
    'Studily Medium Blue UI': 'rgb(56, 67, 84)',
    'Studily Medium UI': 'rgb(55, 66, 83)',
    'Studily Ocean Blue': 'rgb(80, 118, 198)',
    'Studily Primary': 'rgb(85, 108, 246)',
    'Studily Primary UI 2': 'rgb(55, 66, 83)',
    'Studily Sky Blue': 'rgb(86, 184, 244)',
    'Studily Washed Green': 'rgb(126, 209, 172)',
    'Studily_ Blue_Berry': 'rgb(80, 118, 198)',
    Studily_Blue_Night: 'rgb(80, 118, 198)',
    Studily_Blue_White: 'rgb(86, 184, 244)',
    Studily_Bright_Blue: 'rgb(86, 184, 244)',
    Studily_Dark_Green: 'rgb(17, 17, 17)',
    Studily_Dark_Lavender: 'rgb(208, 215, 250)',
    Studily_Dark_Primary: 'rgb(17, 17, 17)',
    Studily_Dark_UI: 'rgb(17, 17, 17)',
    Studily_Emerald: 'rgb(126, 209, 172)',
    Studily_Forrest_Shade: 'rgba(13, 183, 107, 0.73)',
    Studily_Gradient_Studily_1: 'rgb(188, 197, 251)',
    'Studily_Gray 1': 'rgb(227, 231, 249)',
    'Studily_Gray 2': 'rgb(230, 235, 244)',
    'Studily_Gray 3': 'rgb(231, 235, 241)',
    'Studily_Gray 4': 'rgb(236, 240, 246)',
    Studily_Gray_Machine: 'rgb(133, 141, 152)',
    Studily_Lavender_Purple: 'rgb(208, 215, 250)',
    Studily_Light_Blue: 'rgb(55, 66, 83)',
    Studily_Light_Gray_2: 'rgb(133, 141, 152)',
    Studily_Light_Lavender: 'rgb(227, 231, 249)',
    Studily_Light_Navy_Blue: 'rgb(56, 67, 84)',
    Studily_Light_Navy_Secondary: 'rgb(80, 118, 198)',
    Studily_Medium_Lavender: 'rgb(208, 215, 250)',
    Studily_Milk_White: 'rgb(236, 240, 246)',
    Studily_Mint_Green: 'rgb(97, 214, 204)',
    Studily_Opacity_25: 'rgba(0, 0, 0, 0.25)',
    Studily_Primary: 'rgb(85, 108, 246)',
    Studily_Primary_UI_Dark: 'rgb(55, 66, 83)',
    Studily_Purple_Light: 'rgb(208, 215, 250)',
    Studily_Secondary_UI: 'rgb(133, 141, 152)',
    Studily_Silver_White: 'rgb(236, 240, 246)',
    Studily_Sky_Blue_Primary: 'rgb(86, 184, 244)',
    Studily_Slate_Blue_Dark: 'rgb(56, 67, 84)',
    Studily_Slate_Gray: 'rgb(56, 67, 84)',
    Studily_Snow_White: 'rgb(230, 235, 244)',
    Studily_Tab_Divider: 'rgb(231, 235, 241)',
    Studily_Washed_Lavender_White: 'rgb(227, 231, 249)',
    Studily_White: 'rgb(255, 255, 255)',
    Studily_White_1: 'rgb(243, 245, 249)',
    Studily_White_2: 'rgb(239, 239, 239)',
    Studily_White_3: 'rgb(255, 255, 255)',
    Studily_White_4: 'rgb(243, 245, 249)',
    Studily_White_5: 'rgb(236, 240, 246)',
    Studily_White_BG: 'rgb(243, 245, 249)',
    Studily_White_Shade_1: 'rgb(227, 231, 249)',
    Studily_White_Shade_2: 'rgb(230, 235, 244)',
    Studily_White_Shade_3: 'rgb(231, 235, 241)',
    Studily_White_Shade_5: 'rgb(243, 245, 249)',
    Surface: 'rgba(255, 255, 255, 1)',
    Switch_Color_for_Settings_Page: 'rgb(96, 212, 204)',
    Tab_Divider: 'rgb(231, 235, 241)',
    White: 'rgb(255, 255, 255)',
    background: 'rgba(251, 252, 253, 1)',
    custom_rgb0_0_0: 'rgb(0, 0, 0)',
    custom_rgb0_0_255: 'rgb(0, 0, 255)',
    custom_rgb0_128_0: 'rgb(0, 128, 0)',
    custom_rgb102_102_102: 'rgb(102, 102, 102)',
    custom_rgb153_153_153: 'rgb(153, 153, 153)',
    custom_rgb160_171_249: 'rgb(160, 171, 249)',
    custom_rgb16_16_16: 'rgb(16, 16, 16)',
    custom_rgb204_209_250: 'rgb(204, 209, 250)',
    custom_rgb207_207_207: 'rgb(207, 207, 207)',
    custom_rgb218_218_218: 'rgb(218, 218, 218)',
    custom_rgb221_221_221: 'rgb(221, 221, 221)',
    custom_rgb223_223_223: 'rgb(223, 223, 223)',
    custom_rgb233_236_252: 'rgb(233, 236, 252)',
    custom_rgb239_239_239: 'rgb(239, 239, 239)',
    custom_rgb241_243_246: 'rgb(241, 243, 246)',
    custom_rgb245_247_248: 'rgb(245, 247, 248)',
    custom_rgb252_252_207: 'rgb(252, 252, 207)',
    custom_rgb252_252_252: 'rgb(252, 252, 252)',
    custom_rgb253_253_253: 'rgb(253, 253, 253)',
    custom_rgb255_0_0: 'rgb(255, 0, 0)',
    custom_rgb255_165_0: 'rgb(255, 165, 0)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    custom_rgb255_95_0: 'rgb(255, 95, 0)',
    custom_rgb34_34_34: 'rgb(34, 34, 34)',
    custom_rgb51_51_51: 'rgb(51, 51, 51)',
    custom_rgb97_116_246: 'rgb(97, 116, 246)',
    divider: 'rgba(234, 237, 242, 1)',
    error: 'rgba(255, 69, 100, 1)',
    iconOpacity: 'rgba(0, 0, 0, 0.25)',
    internalBackground: 'rgb(160, 171, 249)',
    internalBarelyWhite: 'rgb(253, 253, 253)',
    internalBlondeWhite: 'rgb(223, 223, 223)',
    internalBlue: 'rgb(0, 0, 255)',
    internalBorder: 'rgb(218, 218, 218)',
    internalBronzedGray: 'rgb(153, 153, 153)',
    internalEggWhite: 'rgb(252, 252, 252)',
    internalEverBlueWhite: 'rgb(233, 236, 252)',
    internalHotRed: 'rgb(255, 0, 0)',
    internalLightBackground: 'rgb(241, 243, 246)',
    internalLightGray: 'rgb(207, 207, 207)',
    internalLightIconBG: 'rgb(204, 209, 250)',
    internalLightStone: 'rgb(221, 221, 221)',
    internalLightWhite: 'rgb(239, 239, 239)',
    internalOrangeSecondaryAlt: 'rgb(255, 95, 0)',
    internalPrimaryBold: 'rgb(16, 16, 16)',
    internalPrimaryColor: 'rgb(97, 116, 246)',
    internalPrimaryDark: 'rgb(51, 51, 51)',
    internalSecondaryDark: 'rgb(102, 102, 102)',
    internalSoftSnowWhite: 'rgb(245, 247, 248)',
    internalUIDark: 'rgb(34, 34, 34)',
    internalWhite: 'rgb(255, 255, 255)',
    internalYellow: 'rgb(252, 252, 207)',
    internalYellowSecondary: 'rgb(255, 165, 0)',
    lapinakyva: 'rgba(253, 253, 253, 0)',
    light: 'rgba(165, 173, 183, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    medium: 'rgba(70, 78, 88, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    opacityBlueForButton: 'rgba(85, 108, 246, 0.05)',
    optionSelectedColor: 'rgb(97, 214, 204)',
    overlay: 'rgba(0, 0, 0, 0.39)',
    overlayStyle2: 'rgba(255, 255, 255, 0.49)',
    primary: 'rgb(17, 17, 19)',
    secondary: 'rgba(59, 201, 234, 1)',
    settingsIconBGColor: 'rgb(175, 185, 249)',
    strong: 'rgba(18, 20, 44, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    studily25Percent: 'rgba(0, 0, 0, 0.25)',
    studilyBlueBerry: 'rgb(80, 118, 198)',
    studilyBlueNight: 'rgb(80, 118, 198)',
    studilyBlueWhite: 'rgb(86, 184, 244)',
    studilyBrightBlue: 'rgb(86, 184, 244)',
    studilyBrightMint: 'rgb(126, 209, 172)',
    studilyDarkGreen: 'rgb(17, 17, 17)',
    studilyDarkLavender: 'rgb(208, 215, 250)',
    studilyDarkPrimary: 'rgb(17, 17, 17)',
    studilyDarkUI: 'rgb(17, 17, 17)',
    studilyEmerald: 'rgb(126, 209, 172)',
    studilyEmeraldMint: 'rgb(97, 214, 204)',
    studilyForrestShade: 'rgba(13, 183, 107, 0.73)',
    studilyGradientStudily1: 'rgb(188, 197, 251)',
    studilyGray1: 'rgb(227, 231, 249)',
    studilyGray2: 'rgb(230, 235, 244)',
    studilyGray3: 'rgb(231, 235, 241)',
    studilyGray4: 'rgb(236, 240, 246)',
    studilyGrayMachine: 'rgb(133, 141, 152)',
    studilyLavenderPurple: 'rgb(208, 215, 250)',
    studilyLightBlue: 'rgb(55, 66, 83)',
    studilyLightGray2: 'rgb(133, 141, 152)',
    studilyLightLavender: 'rgb(227, 231, 249)',
    studilyLightNavyBlue: 'rgb(56, 67, 84)',
    studilyLightNavySecondary: 'rgb(80, 118, 198)',
    studilyMediumBlueUI: 'rgb(56, 67, 84)',
    studilyMediumLavender: 'rgb(208, 215, 250)',
    studilyMediumUI: 'rgb(55, 66, 83)',
    studilyMilkWhite: 'rgb(236, 240, 246)',
    studilyMintGreen: 'rgb(97, 214, 204)',
    studilyOceanBlue: 'rgb(80, 118, 198)',
    studilyOpacity25: 'rgba(0, 0, 0, 0.25)',
    studilyPrimary: 'rgb(85, 108, 246)',
    studilyPrimaryUI2: 'rgb(55, 66, 83)',
    studilyPrimaryUIDark: 'rgb(55, 66, 83)',
    studilyPurpleLight: 'rgb(208, 215, 250)',
    studilySecondaryUI: 'rgb(133, 141, 152)',
    studilySilverWhite: 'rgb(236, 240, 246)',
    studilySkyBlue: 'rgb(86, 184, 244)',
    studilySkyBluePrimary: 'rgb(86, 184, 244)',
    studilySlateBlueDark: 'rgb(56, 67, 84)',
    studilySlateGray: 'rgb(56, 67, 84)',
    studilySnowWhite: 'rgb(230, 235, 244)',
    studilyTabDivider: 'rgb(231, 235, 241)',
    studilyWashedGreen: 'rgb(126, 209, 172)',
    studilyWashedLavenderWhite: 'rgb(227, 231, 249)',
    studilyWhite: 'rgb(255, 255, 255)',
    studilyWhite1: 'rgb(243, 245, 249)',
    studilyWhite2: 'rgb(239, 239, 239)',
    studilyWhite3: 'rgb(255, 255, 255)',
    studilyWhite4: 'rgb(243, 245, 249)',
    studilyWhite5: 'rgb(236, 240, 246)',
    studilyWhiteBG: 'rgb(243, 245, 249)',
    studilyWhiteShade1: 'rgb(227, 231, 249)',
    studilyWhiteShade2: 'rgb(230, 235, 244)',
    studilyWhiteShade3: 'rgb(231, 235, 241)',
    studilyWhiteShade5: 'rgb(243, 245, 249)',
    surface: 'rgba(255, 255, 255, 1)',
    switchColorForSettingsPage: 'rgb(96, 212, 204)',
    tabDivider: 'rgb(231, 235, 241)',
    white: 'rgb(255, 255, 255)',
  },
  typography: {
    body1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    body2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    button: {
      ...systemWeights.bold,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    caption: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 16,
    },
    headline1: {
      ...systemWeights.bold,
      fontSize: 60,
      letterSpacing: 0,
      lineHeight: 71,
    },
    headline2: {
      ...systemWeights.bold,
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 58,
    },
    headline3: {
      ...systemWeights.bold,
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 40,
    },
    headline4: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 34,
    },
    headline5: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 26,
    },
    headline6: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 24,
    },
    overline: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 2,
      lineHeight: 16,
    },
    subtitle1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    subtitle2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    custom14: {
      ...systemWeights.regular,
      fontSize: 17,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom15: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom16: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom17: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom18: {
      ...systemWeights.extralight,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom19: {
      ...systemWeights.extralight,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom20: {
      ...systemWeights.extralight,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom21: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom22: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom23: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom24: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom25: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 1,
      lineHeight: 20,
    },
    custom26: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 1,
      lineHeight: 20,
    },
  },
};
