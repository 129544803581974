import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import { ButtonOutline, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, StyleSheet } from 'react-native';

const KorpitrainingScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      navigation.navigate('LoginScreen');
      if (Constants['AUTHORIZATION_HEADER']) {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  return (
    <ScreenContainer
      style={[
        styles.screen,
        { backgroundColor: theme.colors['Custom #101010'] },
      ]}
    >
      <Image
        style={styles.Imaged06b86ef}
        resizeMode={'cover'}
        source={Images.Ljk}
      />
      <ButtonOutline
        onPress={() => {
          try {
            navigation.navigate('Otsikko', { screen: 'EtusivuScreen' });
          } catch (err) {
            console.error(err);
          }
        }}
        style={[
          styles.ButtonOutline32481abe,
          {
            backgroundColor: theme.colors['Custom #101010'],
            color: theme.colors['Internal_Blonde_White'],
          },
        ]}
        title={'SISÄÄN'}
        icon={'Entypo/login'}
        loading={false}
      />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  ButtonOutline32481abe: {
    borderRadius: 8,
    borderWidth: 1,
    fontFamily: 'System',
    fontWeight: '700',
    fontSize: 32,
    marginTop: 25,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    textAlign: 'center',
  },
  Imaged06b86ef: {
    height: 100,
    width: 100,
  },
  screen: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default withTheme(KorpitrainingScreen);
