import React from 'react';
import * as Make$comApi from '../apis/Make$comApi.js';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Utils from '../utils';
import {
  Button,
  ButtonSolid,
  Icon,
  IconButton,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { Image, StyleSheet, Text, View } from 'react-native';

const MaksumuuriScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const xanoAktivointiPOST = XanoApi.useAktivointiPOST();
  const make$comMigraatioPOST = Make$comApi.useMigraatioPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        await xanoAktivointiPOST.mutateAsync({
          email: Constants['USER_EMAIL'],
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      scrollable={false}
      hasTopSafeArea={true}
      hasSafeArea={true}
    >
      <View style={styles.Viewdf76846b}>
        <IconButton
          onPress={() => {
            try {
              setGlobalVariableValue({
                key: 'AUTHORIZATION_HEADER',
                value: '',
              });
              navigation.navigate('LoginScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          icon={'MaterialIcons/exit-to-app'}
        />
        <Image
          style={styles.Image7dd34e6a}
          resizeMode={'cover'}
          source={Images.Ljk}
        />
        <Icon name={'Ionicons/ios-person-circle'} size={30} />
      </View>

      <View style={styles.View6a955cc3}>
        <View
          style={[
            styles.View866906b8,
            {
              borderColor: theme.colors.divider,
              borderRadius: theme.roundness,
              backgroundColor: theme.colors.background,
            },
          ]}
        >
          <View style={styles.Viewe56293e8}>
            <View style={styles.Viewcdc1570e}>
              <View style={styles.Viewbbbc969c}>
                <Icon
                  style={styles.Iconb26c7831}
                  size={34}
                  color={theme.colors.strong}
                  name={'AntDesign/smile-circle'}
                />
              </View>

              <View style={styles.View41ebce54}>
                <Text
                  style={[
                    theme.typography.headline6,
                    { color: theme.colors.strong },
                  ]}
                  allowFontScaling={true}
                  textBreakStrategy={'highQuality'}
                  ellipsizeMode={'tail'}
                >
                  {'Valmennuohjelma'}
                </Text>

                <Text
                  style={[
                    theme.typography.subtitle1,
                    { color: theme.colors.strong },
                  ]}
                  textBreakStrategy={'highQuality'}
                  allowFontScaling={true}
                  ellipsizeMode={'tail'}
                >
                  {'Kaikki ominaisuudet käyttöösi'}
                </Text>
                {/* features-list */}
                <View style={styles.View2362b272}>
                  {/* feature-item */}
                  <View style={styles.View42df60c9}>
                    <View style={styles.View4d536f66}>
                      <Icon
                        style={styles.Icon020a3ec8}
                        color={theme.colors.strong}
                        name={'Entypo/dot-single'}
                        size={24}
                      />
                    </View>

                    <View>
                      <Text
                        style={[
                          theme.typography.body2,
                          { color: theme.colors.medium },
                        ]}
                        ellipsizeMode={'tail'}
                        textBreakStrategy={'highQuality'}
                        allowFontScaling={true}
                      >
                        {'Kuntotaso optimoitu treeniohjelma'}
                      </Text>
                    </View>
                  </View>
                  {/* feature-item */}
                  <View style={styles.View1d4fd775}>
                    <View style={styles.View4d536f66}>
                      <Icon
                        style={styles.Iconfd23fb9d}
                        name={'Entypo/dot-single'}
                        color={theme.colors.strong}
                        size={24}
                      />
                    </View>

                    <View>
                      <Text
                        style={[
                          theme.typography.body2,
                          { color: theme.colors.medium },
                        ]}
                        allowFontScaling={true}
                        ellipsizeMode={'tail'}
                        textBreakStrategy={'highQuality'}
                      >
                        {'Liike kuvat ja videot'}
                      </Text>
                    </View>
                  </View>
                  {/* feature-item */}
                  <View style={styles.View87a818a7}>
                    <View style={styles.View4d536f66}>
                      <Icon
                        style={styles.Iconfd23fb9d}
                        size={24}
                        color={theme.colors.strong}
                        name={'Entypo/dot-single'}
                      />
                    </View>

                    <View>
                      <Text
                        style={[
                          theme.typography.body2,
                          { color: theme.colors.medium },
                        ]}
                        textBreakStrategy={'highQuality'}
                        allowFontScaling={true}
                        ellipsizeMode={'tail'}
                      >
                        {'Valmentaja chat'}
                      </Text>
                    </View>
                  </View>
                  {/* feature-item */}
                  <View style={styles.Viewa751b6c1}>
                    <View style={styles.Viewb80300f2}>
                      <Icon
                        style={styles.Iconfea4aed7}
                        name={'Entypo/dot-single'}
                        size={24}
                        color={theme.colors.strong}
                      />
                    </View>

                    <View>
                      <Text
                        style={[
                          theme.typography.body2,
                          { color: theme.colors.medium },
                        ]}
                        allowFontScaling={true}
                        ellipsizeMode={'tail'}
                        textBreakStrategy={'highQuality'}
                      >
                        {'Plus paljon muuta...'}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <Button
            onPress={() => {
              const handler = async () => {
                try {
                  await WebBrowser.openBrowserAsync(
                    'http://www.korpitraining.fi'
                  );
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={[styles.Button88b1d0bb, { borderRadius: theme.roundness }]}
            type={'solid'}
          >
            {'Siirry verkkokauppaan'}
          </Button>

          <Text style={[styles.Text639dc8ce, { color: theme.colors.strong }]}>
            {
              'Mikäli olit vanhan sovelluksen käyttäjä, voit aktivoida tilisi alta, jos teit tunnuksen samalla sähköpostiosoitteella'
            }
          </Text>
          <ButtonSolid
            onPress={() => {
              const handler = async () => {
                try {
                  await make$comMigraatioPOST.mutateAsync({
                    email: Constants['USER_EMAIL'],
                  });
                  Utils.showAlert({
                    title: 'Aktivointi',
                    message:
                      'Tilisi aktivoituu 1-2 minuutissa. Kirjaudu ulos ja takaisin sisään.',
                    buttonText: 'Sulje',
                  });
                  setGlobalVariableValue({
                    key: 'AUTHORIZATION_HEADER',
                    value: '',
                  });
                  navigation.navigate('LoginScreen');
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={[
              styles.ButtonSolidf5382bb7,
              { backgroundColor: theme.colors.primary },
            ]}
            title={'Aktivoi'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  Button88b1d0bb: {
    alignItems: 'center',
    height: 54,
    justifyContent: 'center',
  },
  ButtonSolidf5382bb7: {
    borderRadius: 8,
    fontFamily: 'System',
    fontWeight: '700',
    marginTop: 15,
    textAlign: 'center',
  },
  Icon020a3ec8: {
    height: 24,
    maxHeight: 24,
    maxWidth: 24,
    width: 24,
  },
  Iconb26c7831: {
    height: 34,
    maxHeight: 34,
    maxWidth: 34,
    width: 34,
  },
  Iconfd23fb9d: {
    height: 24,
    maxHeight: 24,
    maxWidth: 24,
    width: 24,
  },
  Iconfea4aed7: {
    height: 24,
    maxHeight: 24,
    maxWidth: 24,
    width: 24,
  },
  Image7dd34e6a: {
    height: 50,
    width: 75,
  },
  Text639dc8ce: {
    marginTop: 30,
  },
  View1d4fd775: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 2,
    marginTop: 2,
    width: '100%',
  },
  View2362b272: {
    marginTop: 10,
  },
  View41ebce54: {
    paddingRight: 34,
  },
  View42df60c9: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 2,
    marginTop: 2,
    width: '100%',
  },
  View4d536f66: {
    height: 24,
    marginRight: 8,
    width: 24,
  },
  View6a955cc3: {
    justifyContent: 'center',
  },
  View866906b8: {
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopWidth: 1,
    justifyContent: 'center',
    marginBottom: 24,
    marginTop: 24,
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
    width: '100%',
  },
  View87a818a7: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 2,
    marginTop: 2,
    width: '100%',
  },
  Viewa751b6c1: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 2,
    marginTop: 2,
    width: '100%',
  },
  Viewb80300f2: {
    height: 24,
    marginRight: 8,
    width: 24,
  },
  Viewbbbc969c: {
    height: 34,
    marginRight: 14,
    width: 34,
  },
  Viewcdc1570e: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    maxWidth: '80%',
    width: '100%',
  },
  Viewdf76846b: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  Viewe56293e8: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 24,
    width: '100%',
  },
});

export default withTheme(MaksumuuriScreen);
