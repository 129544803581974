import React from 'react';
import * as XanoApi from '../apis/XanoApi.js';
import {
  ButtonSolid,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const LammittelyloppuverkkalaliikkeetScreen = props => {
  const { theme } = props;
  const { navigation } = props;

  return (
    <ScreenContainer
      style={[styles.screen, { backgroundColor: theme.colors.divider }]}
      hasSafeArea={true}
      scrollable={true}
      hasTopSafeArea={true}
    >
      {/* Header Wrapper */}
      <View
        style={[styles.View2ac2efd4, { backgroundColor: theme.colors.overlay }]}
      >
        <View style={styles.Viewbbb12e83}>
          <Text
            style={[styles.Textd800b50f, { color: theme.colors.mediumInverse }]}
          >
            {props.route?.params?.kuvaus ?? ''}
          </Text>
        </View>
      </View>
      {/* Liikkeet */}
      <View style={styles.View4607ad4d}>
        <Text style={[styles.Text22d88b3d, { color: theme.colors.medium }]}>
          {'Liikkeet'}
        </Text>

        <XanoApi.FetchLammittelyLiikkeetGET
          treeni={props.route?.params?.treenin_nimi ?? ''}
        >
          {({ loading, error, data, refetchLammittelyLiikkeet }) => {
            const fetchData = data;
            if (!fetchData || loading) {
              return <ActivityIndicator />;
            }

            if (error) {
              return (
                <Text style={{ textAlign: 'center' }}>
                  There was a problem fetching this data
                </Text>
              );
            }

            return (
              <FlatList
                data={fetchData}
                listKey={'CcqYhobK'}
                keyExtractor={item => item?.id || item?.uuid || item}
                renderItem={({ item }) => {
                  const listData = item;
                  return (
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('LiikkeensuoritusScreen', {
                            liike_nimi: listData?.Name,
                            liike_kuva: listData?.kuva_url,
                            liike_ohjeet: listData?.Ohjeet,
                            liike_aika: listData?.Aika,
                            liike_painot: listData?.Painot,
                            liike_sarjat: listData?.Sarjat,
                            liike_toistot: listData?.Toistot,
                            liike_matka: listData?.Matka,
                            liike_video: listData?.video_url,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={[
                          styles.View7b1ba6fe,
                          {
                            borderRadius: 5,
                            borderColor: theme.colors.custom_rgb153_153_153,
                            backgroundColor: theme.colors.custom_rgb255_255_255,
                          },
                        ]}
                      >
                        <Image
                          style={styles.Imageecd9f6e6}
                          resizeMode={'cover'}
                          source={{ uri: `${listData?.kuva_url}` }}
                        />
                        <Text
                          style={[
                            styles.Texta7f67625,
                            { color: theme.colors.medium },
                          ]}
                        >
                          {listData?.Name}
                        </Text>
                        <Icon
                          size={24}
                          color={theme.colors.medium}
                          name={'Entypo/chevron-right'}
                        />
                      </View>
                    </Touchable>
                  );
                }}
                contentContainerStyle={styles.FlatLista865c048Content}
              />
            );
          }}
        </XanoApi.FetchLammittelyLiikkeetGET>
      </View>
      <ButtonSolid
        onPress={() => {
          try {
            navigation.goBack();
          } catch (err) {
            console.error(err);
          }
        }}
        style={[
          styles.ButtonSolid5bff7718,
          { backgroundColor: theme.colors.primary },
        ]}
        title={'Takaisin'}
        loading={false}
        disabled={false}
      />
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  ButtonSolid5bff7718: {
    borderRadius: 45,
    fontFamily: 'System',
    fontWeight: '700',
    fontSize: 18,
    marginBottom: 10,
    marginLeft: 18,
    marginRight: 18,
    marginTop: 30,
    textAlign: 'center',
  },
  Fetch431eb058: {
    minHeight: 40,
  },
  FlatLista865c048Content: {
    alignContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  Imageecd9f6e6: {
    height: 100,
    overflow: 'hidden',
    width: 90,
  },
  Text22d88b3d: {
    alignSelf: 'flex-start',
    fontFamily: 'Nunito_500Medium',
    fontSize: 18,
    lineHeight: 15,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  Texta7f67625: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  Textd800b50f: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
    lineHeight: 15,
    marginTop: 10,
    paddingBottom: 10,
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 10,
  },
  View2ac2efd4: {
    flexGrow: 1,
    flexShrink: 0,
    maxHeight: 200,
    minHeight: 100,
  },
  View4607ad4d: {
    alignItems: 'center',
    bottom: 5,
    justifyContent: 'space-between',
    left: 5,
    marginLeft: 10,
    marginTop: 10,
    maxWidth: 330,
    minWidth: 270,
    paddingTop: 5,
    right: 5,
    top: 5,
  },
  View7b1ba6fe: {
    alignContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'dashed',
    borderTopWidth: 1,
    flexDirection: 'row',
    flexGrow: 1,
    height: 75,
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    maxHeight: 75,
    overflow: 'hidden',
    width: 280,
  },
  Viewbbb12e83: {
    marginLeft: 5,
    marginRight: 5,
    marginTop: 15,
  },
  screen: {
    alignContent: 'center',
  },
});

export default withTheme(LammittelyloppuverkkalaliikkeetScreen);
