import React from 'react';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode.js';
import {
  ButtonSolid,
  IconButton,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const SuoritetuttreenitScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  // filter
  const myFilter = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    console.log(list, textInputValue);
    const newList = list.filter(item =>
      item.person.toLowerCase().includes(textInputValue)
    );
    return newList;
  };

  const { theme } = props;
  const { navigation } = props;

  const [Suoritetut_treenit, setSuoritetut_treenit] = React.useState([]);
  const [lukumaara, setLukumaara] = React.useState(0);
  const [showNav, setShowNav] = React.useState(false);
  const [sliderValue, setSliderValue] = React.useState(0);

  return (
    <ScreenContainer
      style={styles.screen}
      scrollable={true}
      hasSafeArea={false}
      hasTopSafeArea={true}
    >
      <ImageBackground
        style={styles.ImageBackgrounda98db7de}
        resizeMode={'cover'}
        source={Images._249}
      >
        <View style={styles.Viewdebd3207}>
          <IconButton
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            style={styles.IconButtonf2dad91d}
            size={32}
            icon={'AntDesign/back'}
          />
          <Text style={[styles.Text55ce5766, { color: theme.colors.primary }]}>
            {'Suoritetut treenit'}
          </Text>
        </View>

        <View style={styles.View2c4fedbd}>
          <Text style={[styles.Textbfa225c6, { color: theme.colors.strong }]}>
            {'Suoritettuja treenejä '}
            {lukumaara}
            {' kpl'}
          </Text>
        </View>

        <View>
          <XanoApi.FetchSuoritetuTreenitGET
            email={'a@a.fi'}
            onData={fetchData => {
              try {
                const valueCQLPdIkH = fetchData?.length;
                setLukumaara(valueCQLPdIkH);
                const maara = valueCQLPdIkH;
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {({ loading, error, data, refetchSuoritetuTreenit }) => {
              const fetchData = data;
              if (!fetchData || loading) {
                return <ActivityIndicator />;
              }

              if (error) {
                return (
                  <Text style={{ textAlign: 'center' }}>
                    There was a problem fetching this data
                  </Text>
                );
              }

              return (
                <FlatList
                  data={fetchData}
                  listKey={'joAEBqkF'}
                  keyExtractor={item => item?.id || item?.uuid || item}
                  renderItem={({ item }) => {
                    const listData = item;
                    return (
                      <Touchable style={{ borderRadius: 5 }}>
                        <Surface
                          style={[
                            styles.Surface4475600b,
                            {
                              backgroundColor: theme.colors['Overlay_Style_2'],
                              borderRadius: 5,
                            },
                          ]}
                          elevation={3}
                        >
                          <View
                            style={[styles.View2a3f296f, { borderRadius: 5 }]}
                          >
                            <View style={styles.View3be0cb3d}>
                              <Text
                                style={[
                                  styles.Textdbd37816,
                                  { color: theme.colors['Primary'] },
                                ]}
                              >
                                {listData?.Name}
                              </Text>
                            </View>

                            <Surface
                              style={[
                                styles.Surface439a8e7d,
                                { borderRadius: 6 },
                              ]}
                            >
                              <Image
                                style={styles.Imagea98db7de}
                                resizeMode={'cover'}
                                source={{ uri: `${listData?.kuv_url}` }}
                              />
                            </Surface>
                          </View>
                        </Surface>
                      </Touchable>
                    );
                  }}
                  contentContainerStyle={styles.FlatList2a81e999Content}
                  numColumns={1}
                />
              );
            }}
          </XanoApi.FetchSuoritetuTreenitGET>
          <ButtonSolid
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            style={[
              styles.ButtonSolid8fdab037,
              { backgroundColor: theme.colors.primary },
            ]}
            title={'Takaisin'}
          />
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  ButtonSolid8fdab037: {
    borderRadius: 8,
    fontFamily: 'System',
    fontWeight: '700',
    fontSize: 18,
    marginLeft: 10,
    marginRight: 10,
    textAlign: 'center',
  },
  Fetch431eb058: {
    minHeight: 40,
  },
  FlatList2a81e999Content: {
    flex: 1,
    marginBottom: 50,
  },
  IconButtonf2dad91d: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  ImageBackgrounda98db7de: {
    height: '100%',
    width: '100%',
  },
  Imagea98db7de: {
    height: '100%',
    width: '100%',
  },
  Surface439a8e7d: {
    justifyContent: 'center',
    marginRight: 10,
    overflow: 'hidden',
    width: 130,
  },
  Surface4475600b: {
    alignSelf: 'center',
    height: 50,
    marginBottom: 10,
    marginLeft: 15,
    marginRight: 15,
    minHeight: 40,
    opacity: 0.69,
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 3,
    width: 300,
  },
  Text55ce5766: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 32,
    marginBottom: 10,
    marginLeft: 10,
    marginTop: 10,
  },
  Textbfa225c6: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
  },
  Textdbd37816: {
    fontFamily: 'Nunito_500Medium',
    fontSize: 18,
  },
  View2a3f296f: {
    alignSelf: 'center',
    flexDirection: 'row',
    height: 35,
    justifyContent: 'space-between',
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
    width: 300,
  },
  View2c4fedbd: {
    alignItems: 'center',
    marginTop: 20,
  },
  View3be0cb3d: {
    height: '100%',
    marginLeft: 10,
    width: '50%',
  },
  Viewdebd3207: {
    flexDirection: 'row',
  },
  screen: {
    alignContent: 'center',
    alignSelf: 'stretch',
  },
});

export default withTheme(SuoritetuttreenitScreen);
