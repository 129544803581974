import React from 'react';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Utils from '../utils';
import {
  Checkbox,
  Circle,
  CircleImage,
  Icon,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const ViikotScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const isFocused = useIsFocused();
  React.useEffect(() => {
    console.log('Screen ON_SCREEN_FOCUS Start');
    let error = null;
    try {
      if (!isFocused) {
        return;
      }
      console.log('Start ON_SCREEN_FOCUS:0 TERMINATION_CHECK');
      if (Constants['kuntotesti_suoritettu']) {
        return;
      }
      console.log('Complete ON_SCREEN_FOCUS:0 TERMINATION_CHECK');
      Utils.showAlert({
        title: 'Tasotestit',
        message: 'Tee ensin tasotesti, jotta pääset treenaamaan',
        buttonText: 'Sulje',
      });
      console.log('Start ON_SCREEN_FOCUS:2 NAVIGATE_SCREEN');
      navigation.navigate('Otsikko', { screen: 'TasotestitScreen' });
      console.log('Complete ON_SCREEN_FOCUS:2 NAVIGATE_SCREEN');
    } catch (err) {
      console.error(err);
      error = err.message ?? err;
    }
    console.log(
      'Screen ON_SCREEN_FOCUS Complete',
      error ? { error } : 'no error'
    );
  }, [isFocused]);

  const [Seuraavatreeni, setSeuraavatreeni] = React.useState('');
  const [bannerData, setBannerData] = React.useState(
    'Et ole tehnyt vielä kuntotasotestiä, tee ensin se, jotta pääset treenaamaan'
  );
  const [showBanner, setShowBanner] = React.useState(false);
  const [showNav, setShowNav] = React.useState(false);

  return (
    <ScreenContainer scrollable={true} hasSafeArea={true} hasTopSafeArea={true}>
      {/* Drawer */}
      <>
        {!showNav ? null : (
          <Surface
            style={[
              styles.Surfaceae9b9586,
              { backgroundColor: theme.colors.surface },
            ]}
          >
            <View
              style={[
                styles.View80a1b6f5,
                { backgroundColor: theme.colors.strong },
              ]}
            >
              <View style={styles.View7d6a39b7}>
                <Circle size={64} bgColor={theme.colors.surface}>
                  <CircleImage
                    source={{
                      uri: 'https://global-uploads.webflow.com/5e740d74e6787687577e9b38/61eb13cf6bbef833d45c5560_avatar%20(5).png',
                    }}
                    size={60}
                  />
                </Circle>

                <View style={styles.Viewadfe599e}>
                  <Text
                    style={[
                      styles.Text3cfa611d,
                      { color: theme.colors['Internal_Barely_White'] },
                    ]}
                  >
                    {Constants['name']}
                  </Text>

                  <View style={styles.View76d90f06}>
                    <>
                      {!Constants['aktiivinen'] ? null : (
                        <Circle
                          size={8}
                          bgColor={theme.colors['Studily_Mint_Green']}
                        />
                      )}
                    </>
                    <>
                      {!Constants['aktiivinen'] ? null : (
                        <Text
                          style={[
                            styles.Texta42db935,
                            { color: theme.colors['Studily_Mint_Green'] },
                          ]}
                        >
                          {'Tilaus aktiivinen'}
                        </Text>
                      )}
                    </>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.Viewd35586ee}>
              {/* Etusivu */}
              <Touchable
                onPress={() => {
                  try {
                    setShowNav(false);
                    navigation.navigate('Otsikko', { screen: 'EtusivuScreen' });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View style={styles.Viewbd6f8013}>
                  <Icon size={24} name={'Ionicons/ios-home-outline'} />
                  <Text
                    style={[
                      styles.Text5259fa1a,
                      { color: theme.colors.strong },
                    ]}
                  >
                    {'Etusivu'}
                  </Text>
                </View>
              </Touchable>
              {/* Treeniohjelma */}
              <Touchable
                onPress={() => {
                  try {
                    setShowNav(false);
                    navigation.navigate('Otsikko', { screen: 'ViikotScreen' });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View style={styles.Viewbd6f8013}>
                  <Icon size={24} name={'MaterialCommunityIcons/run'} />
                  <Text
                    style={[
                      styles.Text5259fa1a,
                      { color: theme.colors.strong },
                    ]}
                  >
                    {'Treeniohjelma'}
                  </Text>
                </View>
              </Touchable>
              {/* Messages */}
              <Touchable
                onPress={() => {
                  try {
                    setShowNav(false);
                    navigation.navigate('ChatScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View style={styles.Viewbd6f8013}>
                  <Icon name={'Feather/message-square'} size={24} />
                  <Text
                    style={[
                      styles.Text5259fa1a,
                      { color: theme.colors.strong },
                    ]}
                  >
                    {'Chat'}
                  </Text>
                </View>
              </Touchable>
              {/* TIlastot */}
              <Touchable
                onPress={() => {
                  try {
                    setShowNav(false);
                    navigation.navigate('SuoritetuttreenitScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View style={styles.Viewbd6f8013}>
                  <Icon size={24} name={'Ionicons/ios-stats-chart-outline'} />
                  <Text
                    style={[
                      styles.Text5259fa1a,
                      { color: theme.colors.strong },
                    ]}
                  >
                    {'Suoritetut Treenit'}
                  </Text>
                </View>
              </Touchable>
              {/* Logout */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('LoginScreen');
                    setGlobalVariableValue({
                      key: 'AUTHORIZATION_HEADER',
                      value: '',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View style={styles.View4ac3feef}>
                  <Icon name={'Feather/log-out'} size={24} />
                  <Text
                    style={[
                      styles.Textd06b3023,
                      { color: theme.colors.strong },
                    ]}
                  >
                    {'Kirjaudu ulos'}
                  </Text>
                </View>
              </Touchable>
            </View>
          </Surface>
        )}
      </>
      <ImageBackground
        style={[
          styles.ImageBackground01dc640d,
          { backgroundColor: theme.colors.background },
        ]}
        resizeMode={'cover'}
        backfaceVisibility={'visible'}
        source={Images._249}
      >
        <View style={styles.View1de44df9}>
          <Text style={[styles.Text3bd94042, { color: theme.colors.primary }]}>
            {'Treeniohjelma'}
          </Text>
          {/* openclose */}
          <Checkbox
            onPress={newOpencloseValue => {
              try {
                setShowNav(newOpencloseValue);
              } catch (err) {
                console.error(err);
              }
            }}
            style={styles.Checkbox6dc4da3d}
            status={showNav}
            checkedIcon={'Feather/x'}
            uncheckedIcon={'Feather/menu'}
            size={32}
            color={theme.colors['Internal_Primary_Bold']}
            uncheckedColor={theme.colors['Internal_Primary_Bold']}
          />
        </View>

        <XanoApi.FetchViikotGET email={Constants['USER_EMAIL']}>
          {({ loading, error, data, refetchViikot }) => {
            const fetchData = data;
            if (!fetchData || loading) {
              return <ActivityIndicator />;
            }

            if (error) {
              return (
                <Text style={{ textAlign: 'center' }}>
                  There was a problem fetching this data
                </Text>
              );
            }

            return (
              <FlatList
                data={fetchData?.items}
                listKey={'GedbkTOS'}
                keyExtractor={item => item?.id || item?.uuid || item}
                renderItem={({ item }) => {
                  const listData = item;
                  return (
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('TreenitScreen', {
                            Viikko: listData?.Name,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View style={styles.Viewbdbf7db2}>
                        <Surface
                          style={[
                            styles.Surface6ddbb0e6,
                            {
                              borderRadius: 5,
                              backgroundColor: theme.colors.overlayStyle2,
                              borderColor: theme.colors.custom_rgb34_34_34,
                            },
                          ]}
                          elevation={1}
                        >
                          <ImageBackground
                            style={styles.ImageBackground76da79a6}
                            resizeMode={'cover'}
                            source={{ uri: `${listData?.kuva_url}` }}
                          >
                            <Text
                              style={[
                                styles.Textc4960f4b,
                                { color: theme.colors.custom_rgb255_255_255 },
                              ]}
                            >
                              {listData?.Name}
                            </Text>
                          </ImageBackground>
                        </Surface>
                      </View>
                    </Touchable>
                  );
                }}
                contentContainerStyle={styles.FlatListc992f941Content}
                numColumns={1}
              />
            );
          }}
        </XanoApi.FetchViikotGET>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  Checkbox6dc4da3d: {
    marginRight: 10,
  },
  Fetch431eb058: {
    minHeight: 40,
  },
  FlatListc992f941Content: {
    flex: 1,
  },
  ImageBackground01dc640d: {
    height: '100%',
    width: '100%',
  },
  ImageBackground76da79a6: {
    height: '100%',
    opacity: 1,
    width: '100%',
  },
  Surface6ddbb0e6: {
    height: '100%',
    minHeight: 40,
    opacity: 1,
    overflow: 'hidden',
    width: '100%',
  },
  Surfaceae9b9586: {
    flex: 2,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '80%',
    zIndex: 5,
  },
  Text3bd94042: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 32,
    marginBottom: 10,
    marginLeft: 10,
    marginTop: 10,
  },
  Text3cfa611d: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 18,
  },
  Text5259fa1a: {
    fontFamily: 'System',
    fontWeight: '400',
    fontSize: 18,
    marginLeft: 8,
  },
  Texta42db935: {
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 4,
  },
  Textc4960f4b: {
    alignSelf: 'flex-start',
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
    marginBottom: 50,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 30,
  },
  Textd06b3023: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 18,
    marginLeft: 8,
  },
  View1de44df9: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  View4ac3feef: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 12,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
  },
  View76d90f06: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 4,
  },
  View7d6a39b7: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  View80a1b6f5: {
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 72,
  },
  Viewadfe599e: {
    flex: 1,
    marginLeft: 12,
  },
  Viewbd6f8013: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 12,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
  },
  Viewbdbf7db2: {
    alignSelf: 'stretch',
    height: 100,
    marginBottom: 20,
    marginLeft: 19,
    marginRight: 19,
    marginTop: 19,
    overflow: 'hidden',
  },
  Viewd35586ee: {
    flex: 1,
    paddingBottom: 16,
    paddingTop: 16,
  },
});

export default withTheme(ViikotScreen);
