import React from 'react';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import {
  Circle,
  Divider,
  Icon,
  Link,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import {
  ImageBackground,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const RegisterScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const { theme } = props;
  const { navigation } = props;

  const xanoSignupPOST = XanoApi.useSignupPOST();

  const [nimi, setNimi] = React.useState('');
  const [salasana_pituus, setSalasana_pituus] = React.useState(0);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer
      style={{ backgroundColor: theme.colors.studilyWhiteBG }}
      hasSafeArea={false}
      scrollable={false}
    >
      <ImageBackground
        style={styles.ImageBackgrounda98db7de}
        resizeMode={'cover'}
        source={Images._20091109MG11111}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={styles.KeyboardAwareScrollView1e491474Content}
          showsVerticalScrollIndicator={true}
          keyboardShouldPersistTaps={'never'}
        >
          <View>
            {/* Heading */}
            <View style={styles.View39912261}>
              <Text
                style={[
                  styles.Text2dfdca43,
                  { color: theme.colors['Internal_Blonde_White'] },
                ]}
              >
                {'Rekisteröidy'}
              </Text>

              <Text
                style={[
                  styles.Textdc4a5128,
                  { color: theme.colors['Custom #6174f6'] },
                ]}
              >
                {null}
              </Text>
            </View>
            <Spacer top={16} right={8} bottom={16} left={8} />
            {/* Form */}
            <View>
              <View
                style={[
                  styles.View6f7e8159,
                  {
                    backgroundColor: theme.colors.studilyWhite,
                    borderRadius: 12,
                    borderColor: theme.colors.studilyMilkWhite,
                  },
                ]}
              >
                <View style={styles.Viewe6b20937}>
                  {/* Nimi */}
                  <TextInput
                    onChangeText={newNimiValue => {
                      try {
                        setNimi(newNimiValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={[
                      styles.TextInputde429f84,
                      { color: theme.colors.studilyLightBlue },
                    ]}
                    placeholder={'Anna Nimesi'}
                    value={nimi}
                    keyboardType={'email-address'}
                  />
                </View>

                <View style={styles.Viewe6b20937}>
                  <TextInput
                    onChangeText={newTextInputValue => {
                      try {
                        setTextInputValue(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={[
                      styles.TextInputde429f84,
                      { color: theme.colors.studilyLightBlue },
                    ]}
                    placeholder={'Sähköposti'}
                    value={textInputValue}
                    keyboardType={'email-address'}
                  />
                </View>
                <Divider
                  style={styles.Dividerde11d607}
                  color={theme.colors.studilyMilkWhite}
                />
                <View style={styles.Viewe6b20937}>
                  <TextInput
                    onChangeText={newTextInputValue => {
                      try {
                        setTextInputValue2(newTextInputValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={[
                      styles.TextInputde429f84,
                      { color: theme.colors.studilyLightBlue },
                    ]}
                    placeholder={'Salasana'}
                    value={textInputValue2}
                    secureTextEntry={true}
                  />
                </View>
              </View>
            </View>
            <Spacer top={24} right={8} bottom={24} left={8} />
            {/* Button */}
            <View>
              {/* Touchable Frame */}
              <View style={styles.View7b3021cb}>
                <Touchable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const valueycAZFc3o = textInputValue2?.length;
                        setSalasana_pituus(valueycAZFc3o);
                        const pw_lenght = valueycAZFc3o;
                        const signupResponseJson =
                          await xanoSignupPOST.mutateAsync({
                            signupEmail: textInputValue,
                            signupName: nimi,
                            signupPassword: textInputValue2,
                          });
                        const authToken = signupResponseJson.authToken;
                        const message = signupResponseJson.message;
                        setGlobalVariableValue({
                          key: 'ERROR_MESSAGE',
                          value: message,
                        });
                        if (!authToken) {
                          return;
                        }
                        setGlobalVariableValue({
                          key: 'AUTHORIZATION_HEADER',
                          value: 'Bearer ' + authToken,
                        });
                        navigation.navigate('Otsikko', {
                          screen: 'EtusivuScreen',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                >
                  {/* CTA Frame */}
                  <View
                    style={[
                      styles.View3f736166,
                      {
                        borderRadius: 12,
                        backgroundColor: theme.colors['Primary'],
                      },
                    ]}
                  >
                    {/* Label Frame */}
                    <View style={styles.Vieweccb62ae}>
                      {/* Label */}
                      <Text
                        style={[
                          styles.Text390fbba8,
                          { color: theme.colors.studilyWhite },
                        ]}
                      >
                        {'REKISTERÖIDY'}
                      </Text>
                    </View>
                    {/* Icon Group Frame */}
                    <View>
                      <Circle size={42} bgColor={theme.colors.studilyOpacity25}>
                        {/* Icon Flex Frame */}
                        <View style={styles.View8419f47d}>
                          {/* Icon  */}
                          <Icon
                            name={'AntDesign/arrowright'}
                            size={18}
                            color={theme.colors.studilyWhite}
                          />
                        </View>
                      </Circle>
                    </View>
                  </View>
                </Touchable>
              </View>
            </View>
            <Spacer top={16} right={8} bottom={16} left={8} />
            {/* Login */}
            <View style={styles.View39912261}>
              <Text
                style={[
                  styles.Text2ae5ece0,
                  { color: theme.colors['Internal_Blonde_White'] },
                ]}
              >
                {'Onko sinulla jo tili ?'}
              </Text>
              <Link
                onPress={() => {
                  try {
                    navigation.navigate('LoginScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={[
                  styles.Link89f91622,
                  { color: theme.colors['Primary'] },
                ]}
                title={'KIRJAUDU SISÄÄN'}
              />
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  Dividerde11d607: {
    height: 1,
  },
  ImageBackgrounda98db7de: {
    height: '100%',
    width: '100%',
  },
  KeyboardAwareScrollView1e491474Content: {
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 36,
    paddingLeft: 36,
    paddingRight: 36,
    paddingTop: 36,
  },
  Link89f91622: {
    fontFamily: 'Nunito_700Bold',
    fontSize: 16,
    marginTop: 8,
  },
  Text2ae5ece0: {
    fontFamily: 'Nunito_600SemiBold',
  },
  Text2dfdca43: {
    fontFamily: 'Nunito_700Bold',
    fontSize: 32,
  },
  Text390fbba8: {
    fontFamily: 'Nunito_700Bold',
    fontSize: 15,
    lineHeight: 21,
    marginLeft: 42,
    textTransform: 'uppercase',
  },
  TextInputde429f84: {
    fontFamily: 'Nunito_500Medium',
    fontSize: 16,
  },
  Textdc4a5128: {
    fontFamily: 'Nunito_600SemiBold',
    fontSize: 16,
    marginTop: 8,
    textAlign: 'center',
  },
  View39912261: {
    alignItems: 'center',
  },
  View3f736166: {
    flexDirection: 'row',
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 12,
    zIndex: 10,
  },
  View6f7e8159: {
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopWidth: 1,
    overflow: 'hidden',
  },
  View7b3021cb: {
    zIndex: 10,
  },
  View8419f47d: {
    flexGrow: 0,
    flexShrink: 0,
    paddingBottom: 12,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
  },
  Viewe6b20937: {
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
  },
  Vieweccb62ae: {
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'center',
  },
});

export default withTheme(RegisterScreen);
