import React from 'react';
import * as XanoApi from '../apis/XanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import {
  AccordionGroup,
  ButtonSolid,
  CircleImage,
  Icon,
  IconButton,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const LiikkeetScreen = props => {
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const { theme } = props;
  const { navigation } = props;

  const xanoTreeniSuoritetuksiPATCH = XanoApi.useTreeniSuoritetuksiPATCH();

  return (
    <ScreenContainer
      style={[styles.screen, { backgroundColor: theme.colors.divider }]}
      hasBottomSafeArea={true}
      hasSafeArea={false}
      scrollable={true}
      hasTopSafeArea={true}
    >
      <ImageBackground
        style={styles.ImageBackgrounda98db7de}
        resizeMode={'cover'}
        source={Images._249}
      >
        {/* Header Wrapper */}
        <View
          style={[
            styles.View7c380a45,
            { backgroundColor: theme.colors.overlay },
          ]}
        >
          <View style={styles.View44d96290}>
            <IconButton
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={styles.IconButton589b1f61}
              size={32}
              color={theme.colors.primary}
              icon={'AntDesign/back'}
            />
            <Text
              style={[styles.Text8adac2d7, { color: theme.colors.primary }]}
            >
              {props.route?.params?.treenin_nimi ?? ''}
            </Text>
            <Icon size={24} name={'FontAwesome/ellipsis-v'} />
          </View>

          <View style={styles.View3f5226ef}>
            <View style={styles.View88c44c3e} />
            <Text style={[styles.Textf94cdfca, { color: theme.colors.strong }]}>
              {props.route?.params?.Treenin_kuvaus ?? ''}
            </Text>
          </View>
        </View>
        {/* Lämmittely */}
        <View
          style={[
            styles.View0e72b9ba,
            { backgroundColor: theme.colors.lightInverse },
          ]}
          collapsable={false}
        >
          <XanoApi.FetchLammittelyGET>
            {({ loading, error, data, refetchLammittely }) => {
              const fetchData = data;
              if (!fetchData || loading) {
                return <ActivityIndicator />;
              }

              if (error) {
                return (
                  <Text style={{ textAlign: 'center' }}>
                    There was a problem fetching this data
                  </Text>
                );
              }

              return (
                <AccordionGroup
                  style={styles.AccordionGroupd0855ecb}
                  label={'Lämmittely'}
                  caretSize={40}
                  expanded={false}
                  openColor={theme.colors.medium}
                  caretColor={theme.colors.medium}
                  closedColor={theme.colors.medium}
                >
                  <FlatList
                    data={fetchData}
                    listKey={'k0xerMcf'}
                    keyExtractor={item => item?.id || item?.uuid || item}
                    renderItem={({ item }) => {
                      const listData = item;
                      return (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate(
                                'LammittelyloppuverkkalaliikkeetScreen',
                                {
                                  treenin_nimi: listData?.Name,
                                  nimi: listData?.Name,
                                  kuvaus: listData?.Kuvaus,
                                }
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles.View4ba628f2}>
                            <CircleImage
                              style={styles.CircleImagecfcc4dcb}
                              size={50}
                              source={{ uri: `${listData?.kuv_url}` }}
                            />
                            <Text
                              style={[
                                styles.Text9b229457,
                                { color: theme.colors.medium },
                              ]}
                              adjustsFontSizeToFit={true}
                              allowFontScaling={true}
                            >
                              {listData?.Name}
                            </Text>
                          </View>
                        </Touchable>
                      );
                    }}
                    numColumns={1}
                  />
                </AccordionGroup>
              );
            }}
          </XanoApi.FetchLammittelyGET>
        </View>
        {/* liikkeet */}
        <View style={styles.View06817493}>
          <Text style={[styles.Text57ddafef, { color: theme.colors.medium }]}>
            {'Liikkeet'}
          </Text>

          <XanoApi.FetchLiikkeetGET
            email={Constants['USER_EMAIL']}
            treeni={props.route?.params?.treenin_nimi ?? ''}
          >
            {({ loading, error, data, refetchLiikkeet }) => {
              const fetchData = data;
              if (!fetchData || loading) {
                return <ActivityIndicator />;
              }

              if (error) {
                return (
                  <Text style={{ textAlign: 'center' }}>
                    There was a problem fetching this data
                  </Text>
                );
              }

              return (
                <FlatList
                  data={fetchData}
                  listKey={'C7s2XHp7'}
                  keyExtractor={item => item?.id || item?.uuid || item}
                  renderItem={({ item }) => {
                    const listData = item;
                    return (
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('LiikkeensuoritusScreen', {
                              liike_nimi: listData?.Name,
                              liike_kuva: listData?.kuva_url,
                              liike_ohjeet: listData?.Ohjeet,
                              liike_aika: listData?.Aika,
                              liike_painot: listData?.Painot,
                              liike_sarjat: listData?.Sarjat,
                              liike_toistot: listData?.Toistot,
                              liike_matka: listData?.Matka,
                              liike_video: listData?.Ohjevideo,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={styles.Touchable28910287}
                      >
                        <Surface
                          style={[styles.Surfacec449d671, { borderRadius: 5 }]}
                          elevation={2}
                        >
                          <View
                            style={[
                              styles.Viewa7095a9f,
                              {
                                borderRadius: 5,
                                backgroundColor:
                                  theme.colors.custom_rgb255_255_255,
                              },
                            ]}
                          >
                            <Image
                              style={styles.Imageecd9f6e6}
                              resizeMode={'cover'}
                              source={{ uri: `${listData?.kuva_url}` }}
                            />
                            <View style={styles.Viewb1273fe0}>
                              <Text
                                style={[
                                  styles.Textb42d2e66,
                                  { color: theme.colors.medium },
                                ]}
                                adjustsFontSizeToFit={true}
                                allowFontScaling={false}
                              >
                                {listData?.Name}
                              </Text>
                            </View>
                          </View>
                        </Surface>
                      </Touchable>
                    );
                  }}
                  contentContainerStyle={styles.FlatList5a3136e3Content}
                />
              );
            }}
          </XanoApi.FetchLiikkeetGET>
        </View>
        {/* Loppuverkka */}
        <View
          style={[
            styles.View57a06f08,
            { backgroundColor: theme.colors.lightInverse },
          ]}
          collapsable={false}
        >
          <XanoApi.FetchLoppuverkatGET>
            {({ loading, error, data, refetchLoppuverkat }) => {
              const fetchData = data;
              if (!fetchData || loading) {
                return <ActivityIndicator />;
              }

              if (error) {
                return (
                  <Text style={{ textAlign: 'center' }}>
                    There was a problem fetching this data
                  </Text>
                );
              }

              return (
                <AccordionGroup
                  style={styles.AccordionGroupd0855ecb}
                  label={'Loppuverryttely'}
                  caretSize={40}
                  expanded={false}
                  openColor={theme.colors.medium}
                  caretColor={theme.colors.medium}
                  closedColor={theme.colors.medium}
                >
                  <FlatList
                    data={fetchData}
                    listKey={'YTBU5wGj'}
                    keyExtractor={item => item?.id || item?.uuid || item}
                    renderItem={({ item }) => {
                      const listData = item;
                      return (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate(
                                'LammittelyloppuverkkalaliikkeetScreen',
                                {
                                  treenin_nimi: listData?.Name,
                                  nimi: listData?.Name,
                                  kuvaus: listData?.Kuvaus,
                                }
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View style={styles.View4ba628f2}>
                            <CircleImage
                              style={styles.CircleImagecfcc4dcb}
                              size={50}
                              source={{ uri: `${listData?.kuv_url}` }}
                            />
                            <Text
                              style={[
                                styles.Text9b229457,
                                { color: theme.colors.medium },
                              ]}
                            >
                              {listData?.Name}
                            </Text>
                          </View>
                        </Touchable>
                      );
                    }}
                    numColumns={1}
                  />
                </AccordionGroup>
              );
            }}
          </XanoApi.FetchLoppuverkatGET>
        </View>
        <ButtonSolid
          onPress={() => {
            const handler = async () => {
              try {
                await xanoTreeniSuoritetuksiPATCH.mutateAsync({
                  email: Constants['USER_EMAIL'],
                  treeni: props.route?.params?.treenin_nimi ?? '',
                });
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={[
            styles.ButtonSolidf41da696,
            { backgroundColor: theme.colors.primary },
          ]}
          title={'Merkitse treeni suoritetuksi'}
        />
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  AccordionGroupd0855ecb: {
    alignContent: 'space-around',
    alignSelf: 'flex-start',
    flexDirection: 'row-reverse',
    fontFamily: 'Nunito_500Medium',
    fontSize: 18,
    justifyContent: 'space-evenly',
  },
  ButtonSolidf41da696: {
    borderRadius: 8,
    fontFamily: 'Nunito_700Bold',
    fontSize: 18,
    marginBottom: 20,
    marginLeft: 18,
    marginRight: 18,
    textAlign: 'center',
  },
  CircleImagecfcc4dcb: {
    right: 20,
  },
  Fetch431eb058: {
    minHeight: 40,
  },
  FlatList5a3136e3Content: {
    alignContent: 'center',
    alignItems: 'center',
  },
  IconButton589b1f61: {
    marginLeft: 10,
  },
  ImageBackgrounda98db7de: {
    height: '100%',
    width: '100%',
  },
  Imageecd9f6e6: {
    height: 100,
    overflow: 'hidden',
    width: 90,
  },
  Surfacec449d671: {
    height: 75,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
    minHeight: 40,
    width: 295,
  },
  Text57ddafef: {
    alignSelf: 'flex-start',
    fontFamily: 'Nunito_500Medium',
    fontSize: 18,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  Text8adac2d7: {
    alignSelf: 'center',
    fontFamily: 'Nunito_400Regular',
    fontSize: 32,
  },
  Text9b229457: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
  },
  Textb42d2e66: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 17,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    textAlign: 'left',
  },
  Textf94cdfca: {
    fontFamily: 'Nunito_300Light',
    fontSize: 16,
    paddingBottom: 19,
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 19,
  },
  Touchable28910287: {
    marginBottom: 5,
  },
  View06817493: {
    alignItems: 'center',
    marginBottom: 5,
    marginLeft: 5,
    marginRight: 5,
    marginTop: 10,
    maxWidth: 330,
    minWidth: 270,
    paddingTop: 5,
  },
  View0e72b9ba: {
    marginBottom: 5,
    maxHeight: 220,
    minHeight: 5,
    top: 8,
  },
  View3f5226ef: {
    alignContent: 'center',
    alignSelf: 'center',
    marginLeft: 5,
    marginRight: 5,
    marginTop: 10,
  },
  View44d96290: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    width: '100%',
  },
  View4ba628f2: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
    marginLeft: 30,
    marginRight: 5,
    paddingLeft: 5,
  },
  View57a06f08: {
    marginBottom: 20,
  },
  View7c380a45: {
    alignItems: 'flex-end',
    flexGrow: 1,
    flexShrink: 0,
    maxHeight: 300,
    minHeight: 100,
  },
  View88c44c3e: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  Viewa7095a9f: {
    alignItems: 'center',
    borderStyle: 'dashed',
    flexDirection: 'row',
    height: 75,
    maxHeight: 75,
    overflow: 'hidden',
    paddingRight: 5,
    width: 295,
  },
  Viewb1273fe0: {
    alignSelf: 'center',
    flexDirection: 'row',
  },
  screen: {
    alignContent: 'center',
  },
});

export default withTheme(LiikkeetScreen);
