import React from 'react';
import Images from '../config/Images';
import {
  ButtonSolid,
  IconButton,
  Row,
  ScreenContainer,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { ImageBackground, Modal, StyleSheet, Text, View } from 'react-native';

const LiikkeensuoritusScreen = props => {
  const { theme } = props;
  const { navigation } = props;

  const [video_visible, setVideo_visible] = React.useState(false);

  return (
    <ScreenContainer
      style={{ backgroundColor: theme.colors.divider }}
      hasTopSafeArea={true}
      scrollable={true}
      hasSafeArea={true}
    >
      <ImageBackground
        style={styles.ImageBackgrounda98db7de}
        resizeMode={'cover'}
        source={Images._249}
      >
        {/* Header Wrapper */}
        <View style={styles.Viewd0c2df68}>
          <>
            {!video_visible ? null : (
              <Modal
                visible={video_visible}
                animationType={'fade'}
                presentationStyle={'fullScreen'}
                transparent={true}
              >
                <WebView
                  style={styles.WebViewc992f941}
                  javaScriptEnabled={true}
                  cacheEnabled={true}
                  optimizeVideoChat={true}
                  incognito={true}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  allowFileAccessFromFileURLs={false}
                  allowUniversalAccessFromFileURLs={false}
                  source={{
                    uri: `https://www.youtube.com/embed/${
                      props.route?.params?.liike_video ?? ''
                    }/`,
                  }}
                  mediaPlaybackRequiresUserAction={false}
                />
                <ButtonSolid
                  onPress={() => {
                    try {
                      setVideo_visible(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={[
                    styles.ButtonSolid2d5f6a36,
                    { backgroundColor: theme.colors.primary },
                  ]}
                  title={'Sulje'}
                />
              </Modal>
            )}
          </>
          <ImageBackground
            style={styles.ImageBackground8788d572}
            source={{ uri: `${props.route?.params?.liike_kuva ?? ''}` }}
          >
            <>
              {!(props.route?.params?.liike_video ?? '') ? null : (
                <IconButton
                  onPress={() => {
                    try {
                      setVideo_visible(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  icon={'Foundation/play-video'}
                  color={theme.colors.custom_rgb97_116_246}
                />
              )}
            </>
          </ImageBackground>
        </View>
        {/* Content Wrapper */}
        <View style={styles.View3bdb5092}>
          {/* Item Name */}
          <Text style={[styles.Text38b13737, { color: theme.colors.strong }]}>
            {props.route?.params?.liike_nimi ?? ''}
          </Text>
          {/* Item Description */}
          <Text
            style={[styles.Text3bb377ab, { color: theme.colors.medium }]}
            allowFontScaling={true}
            adjustsFontSizeToFit={true}
          >
            {props.route?.params?.liike_ohjeet ?? ''}
          </Text>
        </View>
        {/* Footer Wrapper */}
        <View
          style={[
            styles.View77a5eb05,
            { backgroundColor: theme.colors.overlayStyle2 },
          ]}
        >
          <Row justifyContent={'space-between'} alignItems={'center'}>
            {/* Item Price Point */}
            <>
              {!(props.route?.params?.liike_sarjat ?? '') ? null : (
                <Text
                  style={[styles.Text917d8a4b, { color: theme.colors.primary }]}
                >
                  {'Sarjat: '}
                  {props.route?.params?.liike_sarjat ?? ''}
                </Text>
              )}
            </>
            {/* Item Price Point */}
            <>
              {!(props.route?.params?.liike_toistot ?? '') ? null : (
                <Text
                  style={[styles.Text917d8a4b, { color: theme.colors.primary }]}
                >
                  {'Toistot: '}
                  {props.route?.params?.liike_toistot ?? ''}
                </Text>
              )}
            </>
            {/* Item Price Point */}
            <>
              {!(props.route?.params?.liike_aika ?? '') ? null : (
                <Text
                  style={[styles.Text917d8a4b, { color: theme.colors.primary }]}
                >
                  {'Aika: '}
                  {props.route?.params?.liike_aika ?? ''}
                </Text>
              )}
            </>
            {/* Item Price Point */}
            <>
              {!(props.route?.params?.liike_matka ?? '') ? null : (
                <Text
                  style={[styles.Text917d8a4b, { color: theme.colors.primary }]}
                >
                  {'Matka: '}
                  {props.route?.params?.liike_matka ?? ''}
                </Text>
              )}
            </>
          </Row>
          <ButtonSolid
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            style={[
              styles.ButtonSolid3a558498,
              { backgroundColor: theme.colors.primary },
            ]}
            title={'Takaisin'}
          />
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  ButtonSolid2d5f6a36: {
    borderRadius: 8,
    fontFamily: 'System',
    fontWeight: '700',
    textAlign: 'center',
  },
  ButtonSolid3a558498: {
    borderRadius: 40,
    fontFamily: 'Nunito_700Bold',
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  IconButton589b1f61: {
    marginLeft: 10,
  },
  IconButton6dc4da3d: {
    marginRight: 10,
  },
  ImageBackground8788d572: {
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  ImageBackgrounda98db7de: {
    height: '100%',
    width: '100%',
  },
  Text38b13737: {
    fontFamily: 'Nunito_600SemiBold',
    fontSize: 32,
    marginTop: 12,
    paddingBottom: 12,
    textAlign: 'auto',
    width: '100%',
  },
  Text3bb377ab: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
    marginBottom: 5,
    marginTop: 5,
    textAlign: 'auto',
    width: '100%',
  },
  Text917d8a4b: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 18,
    marginBottom: 18,
    marginTop: 12,
    textAlign: 'left',
  },
  Textc93d3c0d: {
    fontFamily: 'Nunito_400Regular',
    fontSize: 32,
  },
  View3bdb5092: {
    flex: 1,
    flexGrow: 1,
    flexShrink: 0,
    marginBottom: 10,
    maxHeight: '80%',
    paddingBottom: 5,
    paddingLeft: 18,
    paddingRight: 18,
  },
  View77a5eb05: {
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'space-evenly',
    marginTop: 5,
    maxHeight: '20%',
    minHeight: '15%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  Viewc8111196: {
    marginLeft: 5,
    marginRight: 5,
    marginTop: 10,
  },
  Viewd0c2df68: {
    flexGrow: 1,
    flexShrink: 0,
    maxHeight: '30%',
    minHeight: '30%',
  },
  WebViewc992f941: {
    flex: 1,
  },
});

export default withTheme(LiikkeensuoritusScreen);
